/* Font Family */
/* Borda arredondada */
body > #root > section > main > .headerBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 52px;
  right: 155px;
  width: 40%;
  z-index: 9999;
}
body > #root > section > main > .headerBox > .buttons {
  margin-right: 10px;
  display: flex;
}
body > #root > section > main > .headerBox > .buttons > *:not(:first-child) {
  margin-left: 10px;
}
body > #root > section > main > .headerBox > .buttons > a.btn {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 9px 15px;
  font-size: 12px;
  color: #666;
  background-color: #F0F0F0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
body > #root > section > main > .headerBox > .buttons > a.btn > span:not(:first-child) {
  margin-left: 5px;
}
body > #root > section > main > .headerBox > .buttons > a.btn.back {
  color: rgba(102, 102, 102, 0.7);
  background-color: #d7d7d7;
}
body > #root > section > main > .headerBox > .buttons > a.btn.back:hover {
  color: #333;
  background-color: #cacaca;
}
body > #root > .secEducEmployeesForm > main > .contentForm {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: white;
  padding: 25px;
  max-width: 100%;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
body > #root > .secEducEmployeesForm > main > .contentForm > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0 0;
  max-width: 100%;
  height: 1px;
  background-color: #F0F0F0;
  border: none;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup:not(:first-child) {
  margin-top: 30px;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .box-autocomplete {
  width: 100%;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .box-autocomplete input,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .box-autocomplete select {
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
  background-color: #F0F0F0;
  border: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > .inputLabel,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > .inputLabel:not(:first-child),
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem:not(:first-child),
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .inputLabel:not(:first-child) {
  margin-left: 15px;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > .inputLabel > label,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > label,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .inputLabel > label {
  margin-bottom: 5px;
  color: #666;
  font-size: 0.9rem;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > .inputLabel > input,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > input,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .inputLabel > input,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > .inputLabel select,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem select,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .inputLabel select {
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
  background-color: #F0F0F0;
  border: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > .inputLabel.line,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem.line,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .inputLabel.line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > .inputLabel.line > label,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem.line > label,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .inputLabel.line > label {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin: 0;
  width: auto;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > .inputLabel.line > input,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem.line > input,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .inputLabel.line > input,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem > .inputLabel.line select,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .formItem.line select,
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup .inputLabel.line select {
  margin-left: 10px;
  width: auto;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .wauto {
  max-width: 100% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w100 {
  width: 100% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w95 {
  width: 95% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w90 {
  width: 90% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w85 {
  width: 85% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w80 {
  width: 80% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w75 {
  width: 75% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w70 {
  width: 70% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w65 {
  width: 65% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w60 {
  width: 60% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w55 {
  width: 55% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w50 {
  width: 50% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w45 {
  width: 45% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w40 {
  width: 40% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w35 {
  width: 35% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w30 {
  width: 30% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w25 {
  width: 25% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w20 {
  width: 20% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w15 {
  width: 15% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w10 {
  width: 10% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > .formGroup > .w5 {
  width: 5% !important;
}
body > #root > .secEducEmployeesForm > main > .contentForm > button {
  margin-left: auto;
  padding: 5px 15px;
  margin-top: 30px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
