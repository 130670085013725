/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .schoolsList {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 100%;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .schoolsList > .formGroup {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .schoolsList > .formGroup:not(:first-child) {
  margin-top: 20px;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .schoolsList > .formGroup > .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-right: 10px;
  width: 87%;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .schoolsList > .formGroup > .inputLabel > label {
  font-size: 12px;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .schoolsList > .formGroup > button {
  width: 10%;
  padding: 10px 15px;
  color: #FFF;
  cursor: pointer;
  background-color: #EA4335;
  border: none;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px -1px -1px;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 10px;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > a,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > button,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > a,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px 20px;
  width: 50%;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > a:not(:first-child),
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > button:not(:first-child),
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > a:not(:first-child),
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > button:not(:first-child) {
  margin-left: 10px;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > a.add,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > button.add,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > a.add,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > button.add {
  width: 100%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #c95203;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > a.add:hover,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > button.add:hover,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > a.add:hover,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > button.add:hover {
  color: #FFF;
  background-color: #973e02;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > a.save,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > button.save,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > a.save,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > button.save {
  color: rgba(255, 255, 255, 0.7);
  background-color: #197e19;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > a.save:hover,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > button.save:hover,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > a.save:hover,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > button.save:hover {
  color: #FFF;
  background-color: #156815;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > a.cancel,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > button.cancel,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > a.cancel,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > button.cancel {
  color: rgba(255, 255, 255, 0.7);
  background-color: #EA4335;
}
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > a.cancel:hover,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > .btnLine > button.cancel:hover,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > a.cancel:hover,
body > #root > #modalBox.schoolsEdit > .modalBody > .modalContent > .buttons > button.cancel:hover {
  color: #FFF;
  background-color: #d62516;
}
