/* Font Family */
/* Borda arredondada */
body > #root > section.secEscTransfers > main > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
}
body > #root > section.secEscTransfers > main > .header > button {
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  color: #666;
  cursor: pointer;
  background-color: #D0D0D0;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.secEscTransfers > main > .header > button:not(:first-child) {
  margin-left: 10px;
}
body > #root > section.secEscTransfers > main > .header > button:hover {
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 48, 99, 0.6);
}
body > #root > section.secEscTransfers > main > .header > button.active {
  color: #FFF;
  cursor: default;
  background-color: #003063;
}
body > #root > section.secEscTransfers > main > .transfers {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin-bottom: 20px;
}
