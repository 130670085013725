/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .error {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  font-size: 12px;
  color: #d62516;
  background-color: rgba(234, 67, 53, 0.2);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group:not(:first-child) {
  margin-left: 10px;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  font-size: 13px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: none -80px 30px -55px #000;
  -webkit-box-shadow: none -80px 30px -55px #000;
  box-shadow: none -80px 30px -55px #000;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect > div:hover {
  border-color: #666 !important;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect > div > div:first-child > div {
  color: #000;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect > div > div > div > input {
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect > div:nth-child(4) {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 0;
  overflow: hidden;
  border: 1px solid #666;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect > div:nth-child(4) > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect > div:nth-child(4) > div > div {
  font-size: 12px;
  color: #000;
  background-color: #FFF;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect > div:nth-child(4) > div > div.css-d7l1ni-option {
  color: #000;
  background-color: #D0D0D0;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect > div:nth-child(4) > div > div.css-9x5mqu {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  color: #a81d11;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > .studentsSelect > div:nth-child(4) > div > div[aria-selected="true"] {
  color: #FFF;
  background-color: #0b51e3;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > input,
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > select {
  width: 100%;
  padding: 10px;
  background-color: #FFF;
  border: 1px solid #C0C0C0;
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > input:focus,
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .input-group > select:focus {
  border-color: #666;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .btns > button#btnAdd {
  background-color: #22A822;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .btns > button#btnAdd:hover {
  background-color: #197e19;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.createEditStudent > .modalBody > .modalContent > form > .form-group > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
body > #root > #modalBox.viewStudent > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
}
body > #root > #modalBox.viewStudent > .modalBody > .modalContent > h3 {
  margin: 0 0 5px;
  width: 100%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #003063;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis "...";
}
body > #root > #modalBox.viewStudent > .modalBody > .modalContent > h4 {
  margin: 0 0 5px;
  width: 100%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #0b51e3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis "...";
}
body > #root > #modalBox.viewStudent > .modalBody > .modalContent > p {
  margin: 5px 0;
  font-size: 13px;
}
body > #root > #modalBox.viewStudent > .modalBody > .modalContent > hr {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #F0F0F0;
  border: none;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 0 15px;
  font-size: 13px;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .infos > p {
  margin: 0;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .infos > p:last-child {
  margin-top: 10px;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .infos > p.alert {
  font-weight: 500;
  color: #a81d11;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .btns > button#btnDelete {
  background-color: #EA4335;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .btns > button#btnDelete:hover {
  background-color: #d62516;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .btns > button#btnCancel {
  background-color: #666;
}
body > #root > #modalBox.deleteStudent > .modalBody > .modalContent > .btns > button#btnCancel:hover {
  background-color: #4d4d4d;
}
