/* Font Family */
/* Borda arredondada */
body > #root > section.secEscReports > main > .reports {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin-bottom: 20px;
}
body > #root > section.secEscReports > main > .reports > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  width: 23.5%;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  background-color: #005BAB;
  border: none;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.secEscReports > main > .reports > button:not(:nth-child(4n+4)) {
  margin-right: 2%;
}
body > #root > section.secEscReports > main > .reports > button:nth-child(n+5) {
  margin-top: 2%;
}
body > #root > section.secEscReports > main > .reports > button > span {
  margin-top: 10px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.7);
}
body > #root > section.secEscReports > main > .reports > button:hover {
  color: #FFF;
  background-color: #004078;
}
body > #root > section.secEscReports > main > .reports > button:hover > span {
  color: rgba(255, 255, 255, 0.9);
}
