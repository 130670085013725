/* Font Family */
/* Borda arredondada */
body > #root > .registerPlanning > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > .registerPlanning > main > .headerBoxSearchAndForm > .buttons > a.save,
body > #root > .registerPlanning > main > .headerBoxSearchAndForm > .buttons > button.save {
  color: rgba(255, 255, 255, 0.7);
  background-color: #22A822;
}
body > #root > .registerPlanning > main > .headerBoxSearchAndForm > .buttons > a.save:hover,
body > #root > .registerPlanning > main > .headerBoxSearchAndForm > .buttons > button.save:hover {
  color: #FFF;
  background-color: #197e19;
}
body > #root > .registerPlanning > main > .init {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #FFF;
}
body > #root > .registerPlanning > main > .init > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 40px;
  color: #FFF;
  background-color: #145cf4;
  border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -webkit-border-radius: 8px 8px 0 0;
}
body > #root > .registerPlanning > main > .init > header > h1 {
  font-size: 38px;
  margin-bottom: 15px;
}
body > #root > .registerPlanning > main > .init > header > p {
  margin: 0px;
  color: rgba(255, 255, 255, 0.8);
}
body > #root > .registerPlanning > main > .init > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 30px;
  background-color: #F0F0F0;
  border-radius: 0 0 8px 8px;
  -moz-border-radius: 0 0 8px 8px;
  -webkit-border-radius: 0 0 8px 8px;
}
body > #root > .registerPlanning > main > .init > .content > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 10px;
  padding: 20px;
  width: 33.33%;
  background-color: #FFF;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
body > #root > .registerPlanning > main > .init > .content > div > img {
  width: 60%;
  padding: 20px;
}
body > #root > .registerPlanning > main > .init > .content > div > .inputLabel {
  margin-bottom: 10px;
}
body > #root > .registerPlanning > main > .init > .content > div > button {
  padding: 15px 10px;
  width: 80%;
}
body > #root > .registerPlanning > main > .init > .content > div input,
body > #root > .registerPlanning > main > .init > .content > div select {
  padding: 10px;
  width: 100%;
  font-size: 14px;
  border-color: #c0c0c0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > .registerPlanning > main > .ef,
body > #root > .registerPlanning > main .ei {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  height: 84vh;
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > .registerPlanning > main > .ef > header,
body > #root > .registerPlanning > main .ei > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 30px;
  font-size: 18px;
  background-color: #666;
  border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -webkit-border-radius: 8px 8px 0 0;
}
body > #root > .registerPlanning > main > .ef > header > label,
body > #root > .registerPlanning > main .ei > header > label {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 10px;
  color: rgba(255, 255, 255, 0.9);
}
body > #root > .registerPlanning > main > .ef > header > label > b,
body > #root > .registerPlanning > main .ei > header > label > b {
  margin-right: 10px;
  color: #FFF;
}
body > #root > .registerPlanning > main > .ef > .contentForm,
body > #root > .registerPlanning > main .ei > .contentForm {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 25px;
  max-width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: #FFF;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
body > #root > .registerPlanning > main > .ef > .contentForm > hr,
body > #root > .registerPlanning > main .ei > .contentForm > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0;
  width: 100%;
  min-height: 2px;
  background-color: #C0C0C0;
  border: none;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup:not(:first-child):not(.lines),
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup:not(:first-child):not(.lines) {
  margin-top: 30px;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup.lines:not(:last-child),
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup.lines:not(:last-child) {
  margin-bottom: 30px;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > .inputLabel,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > .inputLabel,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup .inputLabel,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > .inputLabel:not(:first-child),
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > .inputLabel:not(:first-child),
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem:not(:first-child),
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem:not(:first-child),
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup .inputLabel:not(:first-child),
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup .inputLabel:not(:first-child) {
  margin-left: 15px;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > .inputLabel > label,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > .inputLabel > label,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > label,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > label,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup .inputLabel > label,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup .inputLabel > label {
  margin-bottom: 5px;
  color: #666;
  font-size: 0.9rem;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > .inputLabel > input,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > .inputLabel > input,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > input,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > input,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup .inputLabel > input,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup .inputLabel > input,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > .inputLabel select,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > .inputLabel select,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem select,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem select,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup .inputLabel select,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup .inputLabel select {
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
  background-color: #F0F0F0;
  border: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > .inputLabel.line,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > .inputLabel.line,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem.line,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem.line,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup .inputLabel.line,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup .inputLabel.line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > .inputLabel.line > label,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > .inputLabel.line > label,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem.line > label,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem.line > label,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup .inputLabel.line > label,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup .inputLabel.line > label {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin: 0;
  width: auto;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > .inputLabel.line > input,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > .inputLabel.line > input,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem.line > input,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem.line > input,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup .inputLabel.line > input,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup .inputLabel.line > input,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem > .inputLabel.line select,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem > .inputLabel.line select,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > .formItem.line select,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > .formItem.line select,
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup .inputLabel.line select,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup .inputLabel.line select {
  margin-left: 10px;
  width: auto;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > button,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin: 22px 0 0 15px;
  padding: 13px 25px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > button.del,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > button.del {
  color: rgba(255, 255, 255, 0.8);
  background-color: #EA4335;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup > button.del:hover,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup > button.del:hover {
  color: #FFF;
  background-color: #d62516;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup.center,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup.center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
  max-width: 25%;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup.center > .formItem > .inputLabel,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup.center > .formItem > .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > .registerPlanning > main > .ef > .contentForm > .formGroup.center > .formItem > .inputLabel > input,
body > #root > .registerPlanning > main .ei > .contentForm > .formGroup.center > .formItem > .inputLabel > input {
  text-align: center;
}
body > #root > .registerPlanning > main > .ef > .contentForm > button,
body > #root > .registerPlanning > main .ei > .contentForm > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 15px 25px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out !important;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out !important;
  -o-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out !important;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out !important;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out !important;
  -webkit-transition: 'button' 0.2s ease-in-out;
  -moz-transition: 'button' 0.2s ease-in-out;
  -o-transition: 'button' 0.2s ease-in-out;
  -ms-transition: 'button' 0.2s ease-in-out;
  transition: 'button' 0.2s ease-in-out;
}
body > #root > .registerPlanning > main > .ef > .contentForm > button.mg,
body > #root > .registerPlanning > main .ei > .contentForm > button.mg {
  margin-top: 30px;
}
body > #root > .registerPlanning > main > .ef > .contentForm > button.add,
body > #root > .registerPlanning > main .ei > .contentForm > button.add {
  color: rgba(255, 255, 255, 0.8);
  background-color: #22A822;
}
body > #root > .registerPlanning > main > .ef > .contentForm > button.add:hover,
body > #root > .registerPlanning > main .ei > .contentForm > button.add:hover {
  color: #FFF;
  background-color: #197e19;
}
