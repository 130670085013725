/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.viewMenu > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 100%;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .noInfos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 30px 30px 40px;
  color: #a81d11;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > h3 {
  font-size: 16px;
  font-weight: 800;
  color: #003063;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 20px 0;
  width: 100%;
  height: 2px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .line:not(:nth-child(3)) {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #F0F0F0;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .line > h4 {
  margin-bottom: 6px;
  font-size: 14px;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .line > h4 > span {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .line > .desc {
  font-size: 12px;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 20px;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .btns > button#btnCreate,
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .btns > button#btnEdit {
  background-color: #156815;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .btns > button#btnCreate:hover,
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .btns > button#btnEdit:hover {
  background-color: #0d3e0d;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.viewMenu > .modalBody > .modalContent > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-right: 15px;
  overflow-y: scroll;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #F0F0F0;
  border: none;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > h3 {
  margin-bottom: 5px;
  font-size: 16px;
  color: #003063;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > button {
  margin-top: 15px;
  margin-left: 15px;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #999;
  border: none;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > button:not(:disabled) {
  cursor: pointer;
  color: #FFF;
  background-color: #EA4335;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .inputLabel,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .inputLabel:not(:first-child),
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > .inputLabel:not(:first-child) {
  margin-top: 10px;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .inputLabel > label,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > .inputLabel > label {
  font-size: 12px;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .inputLabel > input,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > .inputLabel > input,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .inputLabel > textarea,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > .inputLabel > textarea {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  width: 100%;
  font-size: 13px;
  background-color: #FFF;
  border: 1px solid #999;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .inputLabel > input:hover,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > .inputLabel > input:hover,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .inputLabel > textarea:hover,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > .inputLabel > textarea:hover,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .inputLabel > input:focus,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > .inputLabel > input:focus,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .inputLabel > textarea:focus,
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > form > .formGroup > .line > .inputLabel > textarea:focus {
  border-color: #333;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  width: 100%;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-top: 30px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > .buttons > button.add {
  width: 60%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #c95203;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > .buttons > button.add:hover {
  color: #FFF;
  background-color: #973e02;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > .buttons > button.save {
  margin-left: 5px;
  width: 20%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #197e19;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > .buttons > button.save:hover {
  color: #FFF;
  background-color: #156815;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > .buttons > button.cancel {
  margin-left: 5px;
  width: 20%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #d62516;
}
body > #root > #modalBox.editCreateMenu > .modalBody > .modalContent > .buttons > button.cancel:hover {
  color: #FFF;
  background-color: #a81d11;
}
