/* Font Family */
/* Borda arredondada */
body > #root > section.parents > main > .head {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.parents > main > .head > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 30px;
  font-size: 20px;
  font-weight: 600;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.parents > main > .head > .box:not(:first-child) {
  margin-left: 10px;
}
body > #root > section.parents > main > .head > .box > small {
  font-size: 11px;
}
body > #root > section.parents > main > .head > .box > span {
  font-size: 34px;
  font-weight: bold;
}
body > #root > section.parents > main > .head > .box.name {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 55%;
  color: #FFF;
  background-color: #457EF6;
}
body > #root > section.parents > main > .head > .box.presences {
  width: 15%;
  color: #FFF;
  background-color: #10B981;
}
body > #root > section.parents > main > .head > .box.faults {
  width: 15%;
  color: #FFF;
  background-color: rgba(223, 13, 13, 0.7);
}
body > #root > section.parents > main > .head > .box.avarage {
  width: 15%;
}
body > #root > section.parents > main > .head > .box.avarage.upper > span {
  color: #0c8a60;
}
body > #root > section.parents > main > .head > .box.avarage.down > span {
  color: #c70c0c;
}
body > #root > section.parents > main > .contentBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 20px;
}
body > #root > section.parents > main > .contentBox > .nutrition {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 30%;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.parents > main > .contentBox > .nutrition > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  background-color: #2d70b3;
  border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
}
body > #root > section.parents > main > .contentBox > .nutrition > .header > h4 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 5px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #FFF;
}
body > #root > section.parents > main > .contentBox > .nutrition > .header > small {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.8);
}
body > #root > section.parents > main > .contentBox > .nutrition > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 20px 0;
  padding: 0 20px;
  overflow-y: auto;
}
body > #root > section.parents > main > .contentBox > .nutrition > .infos > .alertInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px 20px 40px;
  font-size: 12px;
  font-weight: 600;
  color: #EA4335;
}
body > #root > section.parents > main > .contentBox > .nutrition > .infos > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 25px 0;
  width: 100%;
  height: 1px;
  background-color: #F0F0F0;
  border: none;
}
body > #root > section.parents > main > .contentBox > .nutrition > .infos > .box > small {
  font-size: 11px;
  font-weight: 600;
  color: #999;
}
body > #root > section.parents > main > .contentBox > .nutrition > .infos > .box > h5 {
  margin: 0 0 10px;
  font-size: 14px;
  color: #333;
}
body > #root > section.parents > main > .contentBox > .nutrition > .infos > .box > .description {
  font-size: 12px;
  color: #666;
}
body > #root > section.parents > main > .contentBox > .nutrition > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 20px 20px;
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  color: #FFF;
  background-color: #00509D;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.parents > main > .contentBox > .nutrition > a:hover {
  background-color: #00366a;
}
body > #root > section.parents > main > .contentBox > .nextActivities {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-left: 20px;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 5px;
  padding: 18px 20px;
  background-color: #087758;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .header > h4 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #FFF;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .header > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .header > a:hover {
  color: #FFF;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .alertInfo,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .alertInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px 20px 40px;
  font-size: 12px;
  font-weight: 600;
  color: #EA4335;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .header,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .header,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .line,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .header > div,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .header > div,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .line > div,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .line > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 16.15px;
  font-size: 12px;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .header > div:not(:first-child),
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .header > div:not(:first-child),
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .line > div:not(:first-child),
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .line > div:not(:first-child) {
  margin-left: 5px;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .header > div.type,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .header > div.type,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .line > div.type,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .line > div.type {
  width: 20%;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .header > div.date,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .header > div.date,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .line > div.date,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .line > div.date {
  width: 20%;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .header > div.title,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .header > div.title,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .line > div.title,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .line > div.title {
  width: 60%;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .header > div,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .header > div {
  padding: 20px 15px;
  font-weight: 600;
  color: #FFF;
  background-color: #0ea271;
}
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .line,
body > #root > section.parents > main > .contentBox > .nextActivities > .activitiesBox > .activitiesContent > .line {
  margin-top: 5px;
}
