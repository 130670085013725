/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > .saving {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  font-size: 14px;
  color: #156815;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentHeader,
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 12px;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentHeader:not(:first-child),
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentLine:not(:first-child) {
  margin-top: 3px;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentHeader > *:not(:first-child),
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentLine > *:not(:first-child) {
  margin-left: 3px;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentHeader > div,
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentLine > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 8px 10px;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentHeader > div.name,
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentLine > div.name {
  width: 80%;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentHeader > div.grade,
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentLine > div.grade {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20%;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentHeader > div.grade > input,
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentLine > div.grade > input {
  width: 100%;
  padding: 5px;
  text-align: center;
  background-color: #FFF;
  border: 1px solid #C0C0C0;
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentHeader > div.grade > input:focus,
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentLine > div.grade > input:focus {
  border-color: #666;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentHeader > div.grade > input.fileHide,
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentLine > div.grade > input.fileHide {
  display: none;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentHeader > div {
  font-weight: 600;
  color: #FFF;
  background-color: #07379b;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .studentLine > div {
  background-color: #F0F0F0;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .studentsList > .alertInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #EA4335;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .btns > button#btnAdd {
  background-color: #22A822;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .btns > button#btnAdd:hover {
  background-color: #197e19;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.gradesTestsCreate > .modalBody > .modalContent > form > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .loading {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  font-size: 14px;
  color: #b04803;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 12px;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine:not(:first-child) {
  margin-top: 3px;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine > *:not(:first-child) {
  margin-left: 3px;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 5px 10px;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine > div.title {
  width: 70%;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine > div.date {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20%;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  width: 10%;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine > button.btnView {
  background-color: #2d70b3;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine > button.btnView:hover {
  color: #FFF;
  background-color: #23568a;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine > button.btnDelete {
  background-color: #DF0D0D;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .testLine > button.btnDelete:hover {
  color: #FFF;
  background-color: #af0a0a;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .testsList > .alertInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #EA4335;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 0 15px;
  font-size: 13px;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .infos > p {
  margin: 0;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .infos > p:last-child {
  margin-top: 10px;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .infos > p.alert {
  font-weight: 500;
  color: #a81d11;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .infos > p > span {
  font-weight: 600;
  font-style: italic;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .btns > button#btnDelete {
  background-color: #EA4335;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .btns > button#btnDelete:hover {
  background-color: #d62516;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .btns > button#btnCancel {
  background-color: #666;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .btns > button#btnCancel:hover {
  background-color: #4d4d4d;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .removeBox > .removing {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  font-size: 14px;
  color: #bf2114;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .btns > button#btnCreate {
  background-color: #23568a;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .btns > button#btnCreate:hover {
  background-color: #193d61;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.gradesTestsList > .modalBody > .modalContent > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentHeader,
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 12px;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentHeader:not(:first-child),
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentLine:not(:first-child) {
  margin-top: 3px;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentHeader > *:not(:first-child),
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentLine > *:not(:first-child) {
  margin-left: 3px;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentHeader > div,
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentLine > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 8px 10px;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentHeader > div.name,
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentLine > div.name {
  width: 80%;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentHeader > div.grade,
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentLine > div.grade {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20%;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentHeader > div {
  font-weight: 600;
  color: #FFF;
  background-color: #07379b;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .studentLine > div {
  background-color: #F0F0F0;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .studentsList > .alertInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #EA4335;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 0;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .btns > button#btnCreate {
  background-color: #23568a;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .btns > button#btnCreate:hover {
  background-color: #193d61;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.gradesTestsView > .modalBody > .modalContent > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .header > .selectBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .header > .selectBox:not(:first-child) {
  margin-left: 10px;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .header > .selectBox > label {
  margin-bottom: 3px;
  font-size: 11px;
  font-weight: 600;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .header > .selectBox > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  width: 100%;
  font-size: 13px;
  background-color: #FFF;
  border: 1px solid #C0C0C0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .header > .selectBox > select:hover,
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .header > .selectBox > select:focus {
  border-color: #999;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .header > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 15px;
  margin-left: 10px;
  padding: 7px 15px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #065F46;
  border: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .header > button:hover {
  color: #FFF;
  background-color: #044734;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 20px 0;
  width: 100%;
  height: 2px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  height: 49vh;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .alertInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #EA4335;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .students {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 5px;
  height: 45vh;
  overflow-y: auto;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .students > *:not(:first-child) {
  margin-top: 3px;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .students > .studentLine,
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .studentHead {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-right: 15px;
  font-size: 12px;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .students > .studentLine > *:not(:first-child),
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .studentHead > *:not(:first-child) {
  margin-left: 3px;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .students > .studentLine > div,
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .studentHead > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 8px 10px;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .students > .studentLine > div.name,
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .studentHead > div.name {
  width: 80%;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .students > .studentLine > div.average,
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .studentHead > div.average {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20%;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .studentHead > div {
  font-weight: 600;
  color: #FFF;
  background-color: #07379b;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .studentHead > div.name {
  width: 80%;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .studentsList > .studentHead > div.average {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20%;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .btns > button#btnGenerate {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #22A822;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .btns > button#btnGenerate:hover {
  background-color: #197e19;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.listAverage > .modalBody > .modalContent > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
