/* Font Family */
/* Borda arredondada */
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > h3 {
  margin: 0 0 15px;
  font-size: 18px;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  align-items: center;
  margin-bottom: 0.5rem;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: 0.5rem;
  background-color: #d0d0d0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.recess {
  background-color: #D64A34 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.holiday {
  background-color: #FF2424 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.teacherHoliday {
  background-color: #C2A53E !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.planning {
  background-color: #4E7FA7 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.schoolMeeting {
  background-color: #A10B95 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.continuingEducation {
  background-color: #E68CC5 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.optionalHoliday {
  background-color: #F58041 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.classCouncil {
  background-color: #9C6895 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.schoolDays {
  background-color: #9FBE39 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.weekend {
  background-color: #9F9F9F !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.pcdDay {
  background-color: #6EACAD !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-color.startEndSchoolYear {
  background-color: #009FE9 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > .legend-item > .legend-text {
  font-size: 12px;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 20px;
  padding: 10px;
  color: #d3f6d3;
  cursor: pointer;
  background-color: #22A822;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .legend > button:hover {
  color: #FFF;
  background-color: #197e19;
}
