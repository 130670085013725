/* Font Family */
/* Borda arredondada */
body > #root > section.supDirTeacher > main > .report {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
}
body > #root > section.supDirTeacher > main > .report > .header,
body > #root > section.supDirTeacher > main > .report > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.supDirTeacher > main > .report > .header:not(:first-child),
body > #root > section.supDirTeacher > main > .report > .line:not(:first-child) {
  margin-top: 5px;
}
body > #root > section.supDirTeacher > main > .report > .header > .name,
body > #root > section.supDirTeacher > main > .report > .line > .name,
body > #root > section.supDirTeacher > main > .report > .header > .age,
body > #root > section.supDirTeacher > main > .report > .line > .age,
body > #root > section.supDirTeacher > main > .report > .header > .gender,
body > #root > section.supDirTeacher > main > .report > .line > .gender,
body > #root > section.supDirTeacher > main > .report > .header > .disciplines,
body > #root > section.supDirTeacher > main > .report > .line > .disciplines {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px 15px;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.supDirTeacher > main > .report > .header > .name:not(:first-child),
body > #root > section.supDirTeacher > main > .report > .line > .name:not(:first-child),
body > #root > section.supDirTeacher > main > .report > .header > .age:not(:first-child),
body > #root > section.supDirTeacher > main > .report > .line > .age:not(:first-child),
body > #root > section.supDirTeacher > main > .report > .header > .gender:not(:first-child),
body > #root > section.supDirTeacher > main > .report > .line > .gender:not(:first-child),
body > #root > section.supDirTeacher > main > .report > .header > .disciplines:not(:first-child),
body > #root > section.supDirTeacher > main > .report > .line > .disciplines:not(:first-child) {
  margin-left: 5px;
}
body > #root > section.supDirTeacher > main > .report > .header > .name,
body > #root > section.supDirTeacher > main > .report > .line > .name {
  width: 50%;
}
body > #root > section.supDirTeacher > main > .report > .header > .disciplines,
body > #root > section.supDirTeacher > main > .report > .line > .disciplines {
  width: 30%;
}
body > #root > section.supDirTeacher > main > .report > .header > .gender,
body > #root > section.supDirTeacher > main > .report > .line > .gender {
  width: 12%;
}
body > #root > section.supDirTeacher > main > .report > .header > .age,
body > #root > section.supDirTeacher > main > .report > .line > .age {
  width: 8%;
}
body > #root > section.supDirTeacher > main > .report > .header > .name,
body > #root > section.supDirTeacher > main > .report > .header > .age,
body > #root > section.supDirTeacher > main > .report > .header > .gender,
body > #root > section.supDirTeacher > main > .report > .header > .disciplines {
  font-weight: bold;
}
body > #root > section.supDirStudent > main > .report {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
}
body > #root > section.supDirStudent > main > .report > .header,
body > #root > section.supDirStudent > main > .report > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.supDirStudent > main > .report > .header:not(:first-child),
body > #root > section.supDirStudent > main > .report > .line:not(:first-child) {
  margin-top: 5px;
}
body > #root > section.supDirStudent > main > .report > .header > .name,
body > #root > section.supDirStudent > main > .report > .line > .name,
body > #root > section.supDirStudent > main > .report > .header > .classs,
body > #root > section.supDirStudent > main > .report > .line > .classs,
body > #root > section.supDirStudent > main > .report > .header > .age,
body > #root > section.supDirStudent > main > .report > .line > .age,
body > #root > section.supDirStudent > main > .report > .header > .teaching,
body > #root > section.supDirStudent > main > .report > .line > .teaching,
body > #root > section.supDirStudent > main > .report > .header > .shift,
body > #root > section.supDirStudent > main > .report > .line > .shift {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px 15px;
  width: 15%;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.supDirStudent > main > .report > .header > .name:not(:first-child),
body > #root > section.supDirStudent > main > .report > .line > .name:not(:first-child),
body > #root > section.supDirStudent > main > .report > .header > .classs:not(:first-child),
body > #root > section.supDirStudent > main > .report > .line > .classs:not(:first-child),
body > #root > section.supDirStudent > main > .report > .header > .age:not(:first-child),
body > #root > section.supDirStudent > main > .report > .line > .age:not(:first-child),
body > #root > section.supDirStudent > main > .report > .header > .teaching:not(:first-child),
body > #root > section.supDirStudent > main > .report > .line > .teaching:not(:first-child),
body > #root > section.supDirStudent > main > .report > .header > .shift:not(:first-child),
body > #root > section.supDirStudent > main > .report > .line > .shift:not(:first-child) {
  margin-left: 5px;
}
body > #root > section.supDirStudent > main > .report > .header > .name,
body > #root > section.supDirStudent > main > .report > .line > .name {
  width: 50%;
}
body > #root > section.supDirStudent > main > .report > .header > .teaching,
body > #root > section.supDirStudent > main > .report > .line > .teaching {
  width: 26%;
}
body > #root > section.supDirStudent > main > .report > .header > .classs,
body > #root > section.supDirStudent > main > .report > .line > .classs,
body > #root > section.supDirStudent > main > .report > .header > .age,
body > #root > section.supDirStudent > main > .report > .line > .age,
body > #root > section.supDirStudent > main > .report > .header > .shift,
body > #root > section.supDirStudent > main > .report > .line > .shift {
  width: 8%;
}
body > #root > section.supDirStudent > main > .report > .header > .name,
body > #root > section.supDirStudent > main > .report > .header > .classs,
body > #root > section.supDirStudent > main > .report > .header > .age,
body > #root > section.supDirStudent > main > .report > .header > .teaching,
body > #root > section.supDirStudent > main > .report > .header > .shift {
  font-weight: bold;
}
body > #root > section.supDirGrades > main > .report {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
}
body > #root > section.supDirGrades > main > .report > .header,
body > #root > section.supDirGrades > main > .report > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.supDirGrades > main > .report > .header:not(:first-child),
body > #root > section.supDirGrades > main > .report > .line:not(:first-child) {
  margin-top: 5px;
}
body > #root > section.supDirGrades > main > .report > .header > .name,
body > #root > section.supDirGrades > main > .report > .line > .name,
body > #root > section.supDirGrades > main > .report > .header > .classs,
body > #root > section.supDirGrades > main > .report > .line > .classs,
body > #root > section.supDirGrades > main > .report > .header > .age,
body > #root > section.supDirGrades > main > .report > .line > .age,
body > #root > section.supDirGrades > main > .report > .header > .teaching,
body > #root > section.supDirGrades > main > .report > .line > .teaching,
body > #root > section.supDirGrades > main > .report > .header > .shift,
body > #root > section.supDirGrades > main > .report > .line > .shift,
body > #root > section.supDirGrades > main > .report > .header > .average,
body > #root > section.supDirGrades > main > .report > .line > .average,
body > #root > section.supDirGrades > main > .report > .header > .faults,
body > #root > section.supDirGrades > main > .report > .line > .faults {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px 15px;
  width: 15%;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.supDirGrades > main > .report > .header > .name:not(:first-child),
body > #root > section.supDirGrades > main > .report > .line > .name:not(:first-child),
body > #root > section.supDirGrades > main > .report > .header > .classs:not(:first-child),
body > #root > section.supDirGrades > main > .report > .line > .classs:not(:first-child),
body > #root > section.supDirGrades > main > .report > .header > .age:not(:first-child),
body > #root > section.supDirGrades > main > .report > .line > .age:not(:first-child),
body > #root > section.supDirGrades > main > .report > .header > .teaching:not(:first-child),
body > #root > section.supDirGrades > main > .report > .line > .teaching:not(:first-child),
body > #root > section.supDirGrades > main > .report > .header > .shift:not(:first-child),
body > #root > section.supDirGrades > main > .report > .line > .shift:not(:first-child),
body > #root > section.supDirGrades > main > .report > .header > .average:not(:first-child),
body > #root > section.supDirGrades > main > .report > .line > .average:not(:first-child),
body > #root > section.supDirGrades > main > .report > .header > .faults:not(:first-child),
body > #root > section.supDirGrades > main > .report > .line > .faults:not(:first-child) {
  margin-left: 5px;
}
body > #root > section.supDirGrades > main > .report > .header > .name,
body > #root > section.supDirGrades > main > .report > .line > .name {
  width: 35%;
}
body > #root > section.supDirGrades > main > .report > .header > .teaching,
body > #root > section.supDirGrades > main > .report > .line > .teaching {
  width: 15%;
}
body > #root > section.supDirGrades > main > .report > .header > .classs,
body > #root > section.supDirGrades > main > .report > .line > .classs,
body > #root > section.supDirGrades > main > .report > .header > .age,
body > #root > section.supDirGrades > main > .report > .line > .age,
body > #root > section.supDirGrades > main > .report > .header > .shift,
body > #root > section.supDirGrades > main > .report > .line > .shift,
body > #root > section.supDirGrades > main > .report > .header > .average,
body > #root > section.supDirGrades > main > .report > .line > .average,
body > #root > section.supDirGrades > main > .report > .header > .faults,
body > #root > section.supDirGrades > main > .report > .line > .faults {
  width: 10%;
}
body > #root > section.supDirGrades > main > .report > .header > .name,
body > #root > section.supDirGrades > main > .report > .header > .classs,
body > #root > section.supDirGrades > main > .report > .header > .age,
body > #root > section.supDirGrades > main > .report > .header > .teaching,
body > #root > section.supDirGrades > main > .report > .header > .shift,
body > #root > section.supDirGrades > main > .report > .header > .average,
body > #root > section.supDirGrades > main > .report > .header > .faults {
  font-weight: bold;
}
body > #root > section.supDirActivities > main > .report {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
}
body > #root > section.supDirActivities > main > .report > .header,
body > #root > section.supDirActivities > main > .report > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.supDirActivities > main > .report > .header:not(:first-child),
body > #root > section.supDirActivities > main > .report > .line:not(:first-child) {
  margin-top: 5px;
}
body > #root > section.supDirActivities > main > .report > .header > .title,
body > #root > section.supDirActivities > main > .report > .line > .title,
body > #root > section.supDirActivities > main > .report > .header > .teacher,
body > #root > section.supDirActivities > main > .report > .line > .teacher,
body > #root > section.supDirActivities > main > .report > .header > .type,
body > #root > section.supDirActivities > main > .report > .line > .type,
body > #root > section.supDirActivities > main > .report > .header > .date,
body > #root > section.supDirActivities > main > .report > .line > .date {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px 15px;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.supDirActivities > main > .report > .header > .title:not(:first-child),
body > #root > section.supDirActivities > main > .report > .line > .title:not(:first-child),
body > #root > section.supDirActivities > main > .report > .header > .teacher:not(:first-child),
body > #root > section.supDirActivities > main > .report > .line > .teacher:not(:first-child),
body > #root > section.supDirActivities > main > .report > .header > .type:not(:first-child),
body > #root > section.supDirActivities > main > .report > .line > .type:not(:first-child),
body > #root > section.supDirActivities > main > .report > .header > .date:not(:first-child),
body > #root > section.supDirActivities > main > .report > .line > .date:not(:first-child) {
  margin-left: 5px;
}
body > #root > section.supDirActivities > main > .report > .header > .title,
body > #root > section.supDirActivities > main > .report > .line > .title {
  width: 45%;
}
body > #root > section.supDirActivities > main > .report > .header > .teacher,
body > #root > section.supDirActivities > main > .report > .line > .teacher {
  width: 35%;
}
body > #root > section.supDirActivities > main > .report > .header > .type,
body > #root > section.supDirActivities > main > .report > .line > .type {
  width: 10%;
}
body > #root > section.supDirActivities > main > .report > .header > .date,
body > #root > section.supDirActivities > main > .report > .line > .date {
  width: 10%;
}
body > #root > section.supDirActivities > main > .report > .header > .title,
body > #root > section.supDirActivities > main > .report > .header > .teacher,
body > #root > section.supDirActivities > main > .report > .header > .type,
body > #root > section.supDirActivities > main > .report > .header > .date {
  font-weight: bold;
}
body > #root > section.supDirTeacher > main > .alert,
body > #root > section.supDirStudent > main > .alert,
body > #root > section.supDirGrades > main > .alert,
body > #root > section.supDirActivities > main > .alert {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 100px;
  font-weight: 500;
  color: #EA4335;
}
