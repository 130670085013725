/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.classInfos > .modalBody > .modalContent > h3 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #003063;
}
body > #root > #modalBox.classInfos > .modalBody > .modalContent > h4 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #457EF6;
}
body > #root > #modalBox.classInfos > .modalBody > .modalContent > p {
  margin: 5px 0;
  font-size: 13px;
}
body > #root > #modalBox.classInfos > .modalBody > .modalContent > hr {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #F0F0F0;
  border: none;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent {
  margin: -14px -13px -13px;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .header,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .header > .name,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .line > .name,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .header > .years,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .line > .years {
  padding: 10px 15px;
  font-size: 13px;
  background-color: #e8e8e8;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .header > .name:not(:first-child),
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .line > .name:not(:first-child),
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .header > .years:not(:first-child),
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .line > .years:not(:first-child) {
  margin-left: 2px;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .header > .name,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .line > .name {
  width: 80%;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .header > .years,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .line > .years {
  width: 20%;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .header {
  overflow: hidden;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .header > .name,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .header > .years {
  padding: 12px 15px;
  font-weight: 600;
  color: #FFF;
  background-color: #00244a;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .line {
  margin-top: 2px;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .line > .name,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .line > .years {
  font-size: 12px;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .alert {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  font-size: 12px;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px -1px -1px;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > a,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px 20px;
  width: 50%;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > a:not(:first-child),
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > button:not(:first-child) {
  margin-left: 10px;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > a.edit,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > button.edit {
  color: rgba(255, 255, 255, 0.7);
  background-color: #197e19;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > a.edit:hover,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > button.edit:hover {
  color: #FFF;
  background-color: #156815;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > a.cancel,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > button.cancel {
  color: rgba(255, 255, 255, 0.7);
  background-color: #EA4335;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > a.cancel:hover,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > button.cancel:hover {
  color: #FFF;
  background-color: #d62516;
}
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > a > span,
body > #root > #modalBox.classStudents > .modalBody > .modalContent > .buttons > button > span {
  margin-left: 10px;
}
body > #root > section > main .classBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 23.5%;
  cursor: default;
  overflow: hidden;
  background-color: #FFF;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section > main .classBox:not(:nth-child(4n+4)) {
  margin-right: 2%;
}
body > #root > section > main .classBox:nth-child(n+5) {
  margin-top: 2%;
}
body > #root > section > main .classBox:hover {
  border-color: #C0C0C0;
}
body > #root > section > main .classBox:hover > .userData > img {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root > section > main .classBox > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
}
body > #root > section > main .classBox > .infos > h3 {
  margin: 0 0 5px;
  width: 100%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #003063;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis "...";
}
body > #root > section > main .classBox > .infos > .school,
body > #root > section > main .classBox > .infos > .teaching,
body > #root > section > main .classBox > .infos .shift {
  margin: 5px 0 0;
  width: 100%;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis "...";
}
body > #root > section > main .classBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 -1px -1px;
}
body > #root > section > main .classBox > .buttons > a,
body > #root > section > main .classBox > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0;
  padding: 15px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #999;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section > main .classBox > .buttons > a:not(:last-child),
body > #root > section > main .classBox > .buttons > button:not(:last-child) {
  border-right: 1px solid #FFF;
}
body > #root > section > main .classBox > .buttons > a.view,
body > #root > section > main .classBox > .buttons > button.view,
body > #root > section > main .classBox > .buttons > a.teachers,
body > #root > section > main .classBox > .buttons > button.teachers {
  width: 50%;
}
body > #root > section > main .classBox > .buttons > a:hover,
body > #root > section > main .classBox > .buttons > button:hover {
  color: #FFF;
}
body > #root > section > main .classBox > .buttons > a:hover.view,
body > #root > section > main .classBox > .buttons > button:hover.view {
  background-color: #23568a;
}
body > #root > section > main .classBox > .buttons > a:hover.teachers,
body > #root > section > main .classBox > .buttons > button:hover.teachers {
  background-color: #197e19;
}
body > #root > section > main .classBox > .buttons > a > span,
body > #root > section > main .classBox > .buttons > button > span {
  margin-left: 10px;
}
