/* Font Family */
/* Borda arredondada */
body > #root > section.warehouse > main > .headerBoxSearchAndForm > .buttons > button.category {
  color: rgba(255, 255, 255, 0.7);
  background-color: #0ea271;
}
body > #root > section.warehouse > main > .headerBoxSearchAndForm > .buttons > button.category:hover {
  color: #FFF;
  background-color: #0a7350;
}
body > #root > section.warehouse > main > .headerBoxSearchAndForm > .buttons > button.measure {
  color: rgba(255, 255, 255, 0.7);
  background-color: #006ad0;
}
body > #root > section.warehouse > main > .headerBoxSearchAndForm > .buttons > button.measure:hover {
  color: #FFF;
  background-color: #00509D;
}
body > #root > section.warehouse > main > .contentBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding-bottom: 20px;
  width: 100%;
}
body > #root > section.warehouse > main > .contentBox > .headerLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .box,
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .box > h5,
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box > h5 {
  margin: 0;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .box {
  width: calc(20% - 10px);
  min-height: 120px;
  color: #FFF;
  background-color: #FFF;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .box:not(:first-child) {
  margin-left: 30px;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .box > h5 {
  font-size: 56px;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .box > small {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .box.requests {
  background-color: #4383AD;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  width: calc(70% - 10px);
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box {
  width: calc(20% - 30px);
  margin-left: 20px;
  color: #333;
  background-color: #FFF;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box:nth-child(1n+6) {
  margin-top: 20px;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box > h5 {
  font-size: 36px;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box > small {
  font-size: 11px;
  color: rgba(51, 51, 51, 0.7);
  text-align: center;
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box.pending {
  color: #FFF;
  background-color: rgba(251, 103, 4, 0.7);
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box.pending > small {
  color: rgba(255, 255, 255, 0.7);
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box.approved {
  color: #FFF;
  background-color: rgba(34, 168, 34, 0.8);
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box.approved > small {
  color: rgba(255, 255, 255, 0.7);
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box.denied {
  color: #FFF;
  background-color: rgba(234, 67, 53, 0.7);
}
body > #root > section.warehouse > main > .contentBox > .headerLine > .categories > .box.denied > small {
  color: rgba(255, 255, 255, 0.7);
}
body > #root > section.warehouse > main > .contentBox > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > section.warehouse > main > .contentBox > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 15px;
  font-size: 20px;
  color: #003063;
}
body > #root > section.warehouse > main > .contentBox > .lastRequests {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  max-height: 50vh;
  overflow-y: scroll;
}
body > #root > section.warehouse > main > .contentBox > .lastRequests > .alertLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 13px;
  color: #DF0D0D;
}
body > #root > section.warehouse > main > .contentBox > .lastRequests > .requestLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.warehouse > main > .contentBox > .lastRequests > .requestLine:not(:first-child) {
  margin-top: 5px;
}
body > #root > section.warehouse > main > .contentBox > .lastRequests > .requestLine > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 25%;
}
body > #root > section.warehouse > main > .contentBox > .lastRequests > .requestLine > div > small {
  font-size: 10px;
  font-weight: 600;
}
body > #root > section.warehouse > main > .contentBox > .lastRequests > .requestLine > div > span {
  font-size: 13px;
}
