/* Font Family */
/* Borda arredondada */
body > #root > section.parentsEnrollment > main > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px;
  font-size: 20px;
  color: #FFF;
  background-color: #457EF6;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.parentsEnrollment > main > h3:not(.noMargin) {
  margin-top: 30px;
}
body > #root > section.parentsEnrollment > main > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 5px;
}
body > #root > section.parentsEnrollment > main > .line > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 12px 15px;
  font-size: 14px;
  color: #333;
  background-color: #FFF;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root > section.parentsEnrollment > main > .line > div:not(:first-child) {
  margin-left: 5px;
}
body > #root > section.parentsEnrollment > main > .line > div > small {
  font-size: 11px;
  font-weight: 600;
  color: #666;
}
body > #root > section.parentsEnrollment > main > .line > div.name {
  width: 40%;
}
body > #root > section.parentsEnrollment > main > .line > div.birth {
  width: 18%;
}
body > #root > section.parentsEnrollment > main > .line > div.age {
  width: 9%;
}
body > #root > section.parentsEnrollment > main > .line > div.sex,
body > #root > section.parentsEnrollment > main > .line > div.color_race {
  width: 15%;
}
body > #root > section.parentsEnrollment > main > .line > div.mother,
body > #root > section.parentsEnrollment > main > .line > div.father {
  width: 35%;
}
body > #root > section.parentsEnrollment > main > .historicBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.parentsEnrollment > main > .historicBox:not(.noMargin) {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #D0D0D0;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 5px;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 12px 15px;
  font-size: 14px;
  color: #333;
  background-color: #FFF;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div:not(:first-child) {
  margin-left: 5px;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div > small {
  font-size: 11px;
  font-weight: 600;
  color: #666;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.school {
  width: 50%;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.city {
  width: 25%;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.network {
  width: 15%;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.year {
  width: 10%;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.type {
  width: 45%;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.class {
  width: 45%;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.period {
  width: 10%;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.status,
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.date_init,
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.date_end,
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.date_inclusion {
  width: 25%;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.status.closed {
  color: #EA4335;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.status.transfer {
  color: #fb6704;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.status.relocation {
  color: #c99603;
}
body > #root > section.parentsEnrollment > main > .historicBox > .line > div.status.active {
  color: #1e931e;
}
