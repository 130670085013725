/* Font Family */
/* Borda arredondada */
body > #root > .teacherPlanning > main > .plans {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 20px;
  width: 100%;
}
body > #root > .teacherPlanning > main > .plans > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 15px;
  padding: 15px 20px;
  font-size: 20px;
  color: #FFF;
  background-color: #457EF6;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > .teacherPlanning > main > .plans > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0;
  width: 100%;
  min-height: 1px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > .teacherPlanning > main > .plans > .childish,
body > #root > .teacherPlanning > main > .plans > .fundamental {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > .teacherPlanning > main > .plans > .childish > h3,
body > #root > .teacherPlanning > main > .plans > .fundamental > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 15px;
  padding: 15px 20px;
  font-size: 20px;
  color: #FFF;
  background-color: #457EF6;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > .teacherPlanning > main > .plans > .childish > .header,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 5px;
  width: 100%;
}
body > #root > .teacherPlanning > main > .plans > .childish > .header > *,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 8px 10px;
  font-size: 12px;
  color: #FFF;
  background-color: #999;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > .teacherPlanning > main > .plans > .childish > .header > *:not(:first-child),
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *:not(:first-child),
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *:not(:first-child),
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *:not(:first-child) {
  margin-left: 3px;
}
body > #root > .teacherPlanning > main > .plans > .childish > .header > *.btn,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *.btn,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.btn,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.btn {
  width: 5%;
}
body > #root > .teacherPlanning > main > .plans > .childish > .header > *.serie,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *.serie,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.serie,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.serie,
body > #root > .teacherPlanning > main > .plans > .childish > .header > *.bimonthly,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *.bimonthly,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.bimonthly,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.bimonthly {
  width: 8%;
}
body > #root > .teacherPlanning > main > .plans > .childish > .header > *.ability,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *.ability,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.ability,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.ability {
  width: 15%;
}
body > #root > .teacherPlanning > main > .plans > .childish > .header > *.description,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *.description,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.description,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.description,
body > #root > .teacherPlanning > main > .plans > .childish > .header > *.experience,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *.experience,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.experience,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.experience {
  width: 32%;
}
body > #root > .teacherPlanning > main > .plans > .childish > .header > *.description2,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *.description2,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.description2,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.description2,
body > #root > .teacherPlanning > main > .plans > .childish > .header > *.knowledge,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *.knowledge,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.knowledge,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.knowledge,
body > #root > .teacherPlanning > main > .plans > .childish > .header > *.theme,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > *.theme,
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.theme,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.theme {
  width: 21.33%;
}
body > #root > .teacherPlanning > main > .plans > .childish > .content,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
body > #root > .teacherPlanning > main > .plans > .childish > .header > div,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > div {
  font-weight: 600;
}
body > #root > .teacherPlanning > main > .plans > .childish > .header > div.btn,
body > #root > .teacherPlanning > main > .plans > .fundamental > .header > div.btn {
  background-color: transparent;
}
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > * {
  color: #333;
  background-color: #FFF;
}
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.btn,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.btn {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  background-color: #0055b0;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .teacherPlanning > main > .plans > .childish > .content > .line > *.btn:hover,
body > #root > .teacherPlanning > main > .plans > .fundamental > .content > .line > *.btn:hover {
  color: #FFF;
  background-color: #003c7d;
}
