/* Font Family */
/* Borda arredondada */
body > #root > section.parentsNutrition > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.parentsNutrition > main > .schedules {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin-bottom: 20px;
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  width: calc(20% - 8px);
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox:not(:first-child) {
  margin-left: 10px;
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox > .week {
  font-size: 11px;
  font-weight: 600;
  color: #666;
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox > .date {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox > .meals {
  font-size: 12px;
  color: #333;
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 10px 0;
  width: 100%;
  height: 1px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox.curr {
  background-color: #22A822;
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox.curr > .week {
  color: rgba(255, 255, 255, 0.8);
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox.curr > .date {
  color: #FFF;
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox.curr > .meals {
  color: rgba(255, 255, 255, 0.9);
}
body > #root > section.parentsNutrition > main > .schedules > .scheduleBox.curr > hr {
  background-color: #a9eda9;
}
body > #root > section.parentsNutrition > main > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 15px;
  font-size: 20px;
  color: #003063;
}
body > #root > section.parentsNutrition > main > .tipsContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 100%;
  max-height: 67.5vh;
}
body > #root > section.parentsNutrition > main > .tipsContent > .head {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 3px;
  padding-right: 15px;
  -webkit-padding-end: 22px !important;
}
body > #root > section.parentsNutrition > main > .tipsContent > .head > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 12px 15px;
  font-size: 12px;
  font-weight: 600;
  color: #FFF;
  background-color: #457EF6;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root > section.parentsNutrition > main > .tipsContent > .head > div:not(:first-child) {
  margin-left: 3px;
}
body > #root > section.parentsNutrition > main > .tipsContent > .head > div.title {
  width: 50%;
}
body > #root > section.parentsNutrition > main > .tipsContent > .head > div.author {
  width: 35%;
}
body > #root > section.parentsNutrition > main > .tipsContent > .head > div.date {
  width: 10%;
}
body > #root > section.parentsNutrition > main > .tipsContent > .head > div.btn {
  width: 5%;
  background-color: transparent;
}
body > #root > section.parentsNutrition > main > .tipsContent > .tips {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
  padding-right: 15px;
  -webkit-padding-end: 5px !important;
  overflow-y: scroll;
}
body > #root > section.parentsNutrition > main > .tipsContent > .tips > .tipLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.parentsNutrition > main > .tipsContent > .tips > .tipLine:not(:first-child) {
  margin-top: 3px;
}
body > #root > section.parentsNutrition > main > .tipsContent > .tips > .tipLine > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-right: 3px;
  padding: 10.8px 15px;
  font-size: 12px;
  background-color: #FFF;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root > section.parentsNutrition > main > .tipsContent > .tips > .tipLine > div.title {
  width: 50%;
}
body > #root > section.parentsNutrition > main > .tipsContent > .tips > .tipLine > div.author {
  width: 35%;
}
body > #root > section.parentsNutrition > main > .tipsContent > .tips > .tipLine > div.date {
  width: 10%;
}
body > #root > section.parentsNutrition > main > .tipsContent > .tips > .tipLine > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 5%;
  color: rgba(255, 255, 255, 0.9);
  background-color: #10B981;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.parentsNutrition > main > .tipsContent > .tips > .tipLine > a:hover {
  color: #FFF;
  background-color: #0ea271;
}
