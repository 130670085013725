/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.monitorView > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
}
body > #root > #modalBox.monitorView > .modalBody > .modalContent > h3 {
  margin: 0 0 5px;
  width: 100%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #003063;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis "...";
}
body > #root > #modalBox.monitorView > .modalBody > .modalContent > p {
  margin: 5px 0;
  font-size: 13px;
}
body > #root > #modalBox.monitorView > .modalBody > .modalContent > hr {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > #modalBox.monitorView > .modalBody > .modalContent > .routes {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.monitorView > .modalBody > .modalContent > .routes > .route_line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 13px;
}
body > #root > #modalBox.monitorView > .modalBody > .modalContent > .routes > .route_line.line {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed #999;
}
body > #root > #modalBox.monitorView > .modalBody > .modalContent > .routes > .route_line > h4 {
  margin: 0 0 5px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #457EF6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis "...";
}
body > #root > #modalBox.monitorView > .modalBody > .modalContent > .routes > .route_line > h4:not(:first-child) {
  margin-top: 20px;
}
body > #root > #modalBox.monitorView > .modalBody > .modalContent > .routes > .route_line > div {
  margin: 3px 0;
}
body > #root > #modalBox.monitorView > .modalBody > .modalContent > .routes > .route_line > hr {
  margin: 10px 0;
  width: 100%;
  height: 1px;
  background-color: #F0F0F0;
  border: none;
}
