/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.planningInfos > .modalBody > .modalContent {
  overflow: auto;
}
body > #root > #modalBox.planningInfos > .modalBody > .modalContent > h3 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #003063;
}
body > #root > #modalBox.planningInfos > .modalBody > .modalContent > h4 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #457EF6;
}
body > #root > #modalBox.planningInfos > .modalBody > .modalContent > p {
  margin: 5px 0;
  font-size: 13px;
}
body > #root > #modalBox.planningInfos > .modalBody > .modalContent > hr {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #F0F0F0;
  border: none;
}
