/* Font Family */
/* Borda arredondada */
.login-social-google .google-btn {
  display: flex;
  align-items: center;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
  height: 42px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s;
}
.login-social-google .google-btn:hover {
  background-color: #357ae8;
}
.login-social-google .google-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 2px;
  height: 100%;
  width: 40px;
}
.login-social-google .google-icon {
  width: 18px;
  height: 18px;
}
.login-social-google .btn-text {
  padding: 0 24px;
  white-space: nowrap;
}
