/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.studentView > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
}
body > #root > #modalBox.studentView > .modalBody > .modalContent > h3 {
  margin: 0 0 15px;
  width: 100%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #003063;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis "...";
}
body > #root > #modalBox.studentView > .modalBody > .modalContent > h4 {
  margin: 0 0 5px;
  width: 100%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #0b51e3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis "...";
}
body > #root > #modalBox.studentView > .modalBody > .modalContent > hr {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #F0F0F0;
  border: none;
}
body > #root > #modalBox.studentView > .modalBody > .modalContent > .studentLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 12px;
}
body > #root > #modalBox.studentView > .modalBody > .modalContent > .studentLine:not(:first-child) {
  margin-top: 5px;
}
body > #root > #modalBox.studentView > .modalBody > .modalContent > .studentLine > .student {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-right: 5px;
  padding: 10px;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.studentView > .modalBody > .modalContent > .studentLine > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.studentView > .modalBody > .modalContent > .studentLine > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 0 20px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  background-color: #2d70b3;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.studentView > .modalBody > .modalContent > .studentLine > .buttons > button:hover {
  color: #FFF;
  background-color: #23568a;
}
body > #root > #modalBox.studentView > .modalBody > .modalContent > .alertInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #EA4335;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > h3 {
  margin: 0 0 5px;
  width: 100%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #003063;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .description {
  margin: 0;
  font-size: 12px;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > hr {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  min-height: 1px;
  background-color: #F0F0F0;
  border: none;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .dayHeader,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 12px;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .dayHeader > .day,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine > .day,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .dayHeader > .status,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine > .status {
  margin-right: 5px;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .dayHeader > .day,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine > .day {
  width: 10%;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .dayHeader > .status,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine > .status {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .dayHeader > .button,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine > .button {
  width: 20%;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .dayHeader {
  margin-bottom: 5px;
  font-weight: 600;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding-right: 10px;
  height: 60vh;
  overflow-y: scroll;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine {
  margin-top: 5px;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine.weekend > .day,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine.weekend > .status,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine.weekend > .button {
  background-color: #F0F0F0;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine.present > .day,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine.present > .status {
  background-color: rgba(34, 168, 34, 0.2);
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine.fault > .day,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine.fault > .status {
  background-color: rgba(234, 67, 53, 0.25);
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine > .day,
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine > .status {
  padding: 9.3px 10px;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine > .button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine > button {
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  background-color: #999;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .daysBox > .dayLine > button:hover {
  color: #FFF;
  background-color: #065F46;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .btns > .error {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 10px;
  font-size: 12px;
  color: #d62516;
  background-color: rgba(234, 67, 53, 0.2);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .btns > button#btnAdd {
  background-color: #22A822;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .btns > button#btnAdd:hover {
  background-color: #197e19;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.presenceView > .modalBody > .modalContent > form > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
