/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent {
  padding: 20px;
}
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .title,
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .prodBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .title > .name,
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .prodBox > .name,
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .title > .quantity,
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .prodBox > .quantity,
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .title > .type,
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .prodBox > .type {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .title > .name,
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .prodBox > .name {
  width: 50%;
}
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .title > .quantity,
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .prodBox > .quantity {
  width: 30%;
}
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .title > .type,
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .prodBox > .type {
  width: 20%;
}
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .title {
  font-size: 14px;
  font-weight: 600;
}
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .prodBox {
  margin-top: 8px;
  font-size: 12px;
}
body > #root > #modalBox.prodsRequest > .modalBody > .modalContent > .prodBox > .name {
  cursor: help;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent {
  padding: 20px;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .header > h2 {
  font-size: 24px;
  color: #003063;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .header > .reqDate {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 12px;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .header > .reqDate > b {
  font-size: 10px;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > hr {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #F0F0F0;
  border: none;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .items {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .items > .title,
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .items > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .items > .title > .prod,
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .items > .line > .prod {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 70%;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .items > .title > .qtty,
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .items > .line > .qtty {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 30%;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .items > .title {
  font-size: 14px;
  font-weight: 600;
}
body > #root > #modalBox.viewRequest > .modalBody > .modalContent > .items > .line {
  margin-top: 8px;
  font-size: 12px;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 0 15px;
  font-size: 13px;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .infos > p {
  margin: 0 0 5px;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .infos > p:last-child {
  margin-top: 10px;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .infos > p.alert {
  font-weight: 500;
  color: #a81d11;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .btns > button#btnDelete {
  background-color: #EA4335;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .btns > button#btnDelete:hover {
  background-color: #d62516;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .btns > button#btnCancel {
  background-color: #666;
}
body > #root > #modalBox.cancelRequest > .modalBody > .modalContent > .btns > button#btnCancel:hover {
  background-color: #4d4d4d;
}
