/* Font Family */
/* Borda arredondada */
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months {
  width: 55%;
  padding-right: 25px;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month {
  margin-bottom: 30px;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > h2 {
  margin-bottom: 15px;
  font-size: 18px;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-weekdays {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 3px;
  font-size: 12px;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin-bottom: 3px;
  margin-right: 3px;
  height: 50px;
  font-size: 12px;
  font-weight: 500;
  color: #999;
  cursor: pointer;
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day > .checkbox {
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -10px;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day > .checkbox > #mult-select {
  width: 20px;
  height: 20px;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day > .checkbox > #mult-select:before {
  position: absolute;
  left: -2px;
  top: 32%;
  height: 50%;
  width: 4px;
  background-color: #006ee3;
  content: "";
  transform: translateX(10px) rotate(-45deg) scale(1);
  transform-origin: left bottom;
  box-shadow: none;
  opacity: 0;
  filter: alpha(opacity=0);
  /* For IE8 and earlier */
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day > .checkbox > #mult-select:after {
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 4px;
  width: 90%;
  background-color: #006ee3;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
  opacity: 0;
  filter: alpha(opacity=0);
  /* For IE8 and earlier */
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day > .checkbox > #mult-select:checked:before {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day > .checkbox > #mult-select:checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day:nth-child(7n + 7) {
  margin-right: 0 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day:nth-last-child(1),
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day:nth-last-child(2),
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day:nth-last-child(3),
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day:nth-last-child(4),
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day:nth-last-child(5),
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day:nth-last-child(6),
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day:nth-last-child(7) {
  margin-bottom: 0 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.other-month {
  cursor: default;
  background-color: #E0E0E0;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.recess {
  color: #FFF;
  background-color: #D64A34 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.holiday {
  color: #FFF;
  background-color: #FF2424 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.teacherHoliday {
  color: #FFF;
  background-color: #C2A53E !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.planning {
  color: #FFF;
  background-color: #4E7FA7 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.schoolMeeting {
  color: #FFF;
  background-color: #A10B95 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.continuingEducation {
  color: #FFF;
  background-color: #E68CC5 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.optionalHoliday {
  color: #FFF;
  background-color: #F58041 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.classCouncil {
  color: #FFF;
  background-color: #9C6895 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.schoolDays {
  color: #FFF;
  background-color: #9FBE39 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.weekend {
  color: #FFF;
  background-color: #9F9F9F !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.pcdDay {
  color: #FFF;
  background-color: #6EACAD !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .calendar-months > .calendar-month > .calendar-grid > .calendar-day.startEndSchoolYear {
  color: #FFF;
  background-color: #009FE9 !important;
}
