/* Font Family */
/* Borda arredondada */
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .bimester-info {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .bimester-info > h3 {
  margin: 0 0 15px;
  font-size: 18px;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .bimester-info > .bimester-dates {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .bimester-info > .bimester-dates > .bimester:not(:first-child) {
  margin-top: 15px;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .bimester-info > .bimester-dates > .bimester > h4 {
  margin-bottom: 3px;
  font-size: 14px;
  color: #666;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .bimester-info > .bimester-dates > .bimester > label {
  font-size: 12px;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .bimester-info > .bimester-dates > .bimester > label:not(:nth-child(2n)) {
  margin-left: 20px;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .bimester-info > .bimester-dates > .bimester > label > span {
  margin-right: 8px;
  font-weight: 500;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .bimester-info > .bimester-dates > .bimester > label > input[type="date"] {
  padding: 5px;
  width: 35%;
  text-align: center;
  border: none;
  background-color: #e3e3e3;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > .bimester-info > .letive-days {
  margin-top: 15px;
  font-size: 14px;
}
