/* Font Family */
/* Borda arredondada */
body > #root > section.parentsStudentRecord > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-bottom: 20px;
}
body > #root > section.parentsStudentRecord > main > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px;
  font-size: 20px;
  color: #FFF;
  background-color: #457EF6;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.parentsStudentRecord > main > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0;
  width: 100%;
  min-height: 1px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > section.parentsStudentRecord > main > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 5px;
}
body > #root > section.parentsStudentRecord > main > .line > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 12px 15px;
  font-size: 14px;
  color: #333;
  background-color: #FFF;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root > section.parentsStudentRecord > main > .line > div:not(:first-child) {
  margin-left: 5px;
}
body > #root > section.parentsStudentRecord > main > .line > div > small {
  font-size: 11px;
  font-weight: 600;
  color: #666;
}
body > #root > section.parentsStudentRecord > main > .line > div.name {
  width: 40%;
}
body > #root > section.parentsStudentRecord > main > .line > div.birth {
  width: 18%;
}
body > #root > section.parentsStudentRecord > main > .line > div.age {
  width: 9%;
}
body > #root > section.parentsStudentRecord > main > .line > div.birth_local {
  width: 19%;
}
body > #root > section.parentsStudentRecord > main > .line > div.nationality {
  width: 12%;
}
body > #root > section.parentsStudentRecord > main > .line > div.sex,
body > #root > section.parentsStudentRecord > main > .line > div.blood_type,
body > #root > section.parentsStudentRecord > main > .line > div.organ_donor,
body > #root > section.parentsStudentRecord > main > .line > div.color_race,
body > #root > section.parentsStudentRecord > main > .line > div.quilombola {
  width: 20%;
}
body > #root > section.parentsStudentRecord > main > .line > div.address,
body > #root > section.parentsStudentRecord > main > .line > div.school_address {
  width: 45%;
}
body > #root > section.parentsStudentRecord > main > .line > div.neighborhood,
body > #root > section.parentsStudentRecord > main > .line > div.school_neighborhood {
  width: 20%;
}
body > #root > section.parentsStudentRecord > main > .line > div.cep,
body > #root > section.parentsStudentRecord > main > .line > div.school_cep {
  width: 15%;
}
body > #root > section.parentsStudentRecord > main > .line > div.city,
body > #root > section.parentsStudentRecord > main > .line > div.school_city {
  width: 20%;
}
body > #root > section.parentsStudentRecord > main > .line > div.mother,
body > #root > section.parentsStudentRecord > main > .line > div.father {
  width: 50%;
}
body > #root > section.parentsStudentRecord > main > .line > div.internet,
body > #root > section.parentsStudentRecord > main > .line > div.family_bag,
body > #root > section.parentsStudentRecord > main > .line > div.family_bag_cod {
  width: 33.33%;
}
body > #root > section.parentsStudentRecord > main > .line > div.school {
  width: 75%;
}
body > #root > section.parentsStudentRecord > main > .line > div.school_network {
  width: 25%;
}
body > #root > section.parentsStudentRecord > main > .line > div.school_class {
  width: 20%;
}
body > #root > section.parentsStudentRecord > main > .line > div.school_year {
  width: 20%;
}
body > #root > section.parentsStudentRecord > main > .line > div.school_period {
  width: 25%;
}
body > #root > section.parentsStudentRecord > main > .line > div.school_teaching {
  width: 45%;
}
body > #root > section.parentsStudentRecord > main > .defBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 20px;
}
body > #root > section.parentsStudentRecord > main > .defBox > h4 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #FFF;
  background-color: rgba(251, 103, 4, 0.7);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.parentsStudentRecord > main > .defBox > .lines {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
body > #root > section.parentsStudentRecord > main > .defBox > .lines > .dates {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 5px;
  padding: 0;
  width: 100%;
  background-color: transparent;
}
body > #root > section.parentsStudentRecord > main > .defBox > .lines > .dates > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px;
  width: 25%;
  background-color: rgba(254, 209, 180, 0.6);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.parentsStudentRecord > main > .defBox > .lines > .dates > div:not(:first-child) {
  margin-left: 5px;
}
body > #root > section.parentsStudentRecord > main > .defBox > .lines > .dates > div > small {
  font-size: 11px;
  font-weight: 600;
  color: #c95203;
}
body > #root > section.parentsStudentRecord > main > .defBox > .lines > .dates > div > span {
  font-size: 14px;
  color: #973e02;
}
body > #root > section.parentsStudentRecord > main > .defBox > .lines > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 5px;
  padding: 15px 20px;
  width: calc(33.33% - 3.3px);
  font-size: 12px;
  color: #973e02;
  background-color: rgba(254, 225, 205, 0.4);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.parentsStudentRecord > main > .defBox > .lines > .line:not(:nth-child(4n+4)) {
  margin-right: 5px;
}
body > #root > section.parentsStudentRecord > main > .defBox > .lines > .line > span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 5px;
}
