/* Font Family */
/* Borda arredondada */
body > #root > section.transportDrivers > main > .drivers > .driversBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 23.5%;
  cursor: default;
  overflow: hidden;
  background-color: #FFF;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox:not(:nth-child(4n+4)) {
  margin-right: 2%;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox:nth-child(n+5) {
  margin-top: 2%;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox:hover {
  border-color: #C0C0C0;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox > .infos > h3 {
  margin: 0 0 5px;
  width: 100%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #003063;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis "...";
}
body > #root > section.transportDrivers > main > .drivers > .driversBox > .infos > .routes {
  font-size: 12px;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 -1px -1px;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons > a,
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0;
  padding: 15px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #999;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons > a:not(:last-child),
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons > button:not(:last-child) {
  border-right: 1px solid #FFF;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons > a:hover,
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons > button:hover {
  color: #FFF;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons > a:hover.view,
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons > button:hover.view {
  background-color: #23568a;
}
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons > a > span,
body > #root > section.transportDrivers > main > .drivers > .driversBox > .buttons > button > span {
  margin-left: 10px;
}
