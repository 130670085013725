/* Font Family */
/* Borda arredondada */
body > #root > section.nutrition > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.nutrition > main > .contentBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-bottom: 20px;
  width: 100%;
}
body > #root > section.nutrition > main > .contentBox > .boxLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  width: 100%;
}
body > #root > section.nutrition > main > .contentBox > .boxLine:not(:first-child) {
  margin-top: 20px;
}
body > #root > section.nutrition > main > .contentBox > .boxLine > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  width: calc(20% - 12px);
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section.nutrition > main > .contentBox > .boxLine > .box:not(:first-child):not(:nth-child(5n+6)) {
  margin-left: 15px;
}
body > #root > section.nutrition > main > .contentBox > .boxLine > .box:nth-child(n+6) {
  margin-top: 15px;
}
body > #root > section.nutrition > main > .contentBox > .boxLine > .box > h5 {
  margin: 0;
  font-size: 36px;
}
body > #root > section.nutrition > main > .contentBox > .boxLine > .box > small {
  font-size: 11px;
  color: rgba(51, 51, 51, 0.7);
  text-align: center;
}
body > #root > section.nutrition > main > .contentBox > .boxLine.two > .box {
  width: calc(50% - 8px);
}
body > #root > section.nutrition > main > .contentBox > .boxLine.two > .box.schedules {
  background-color: #4383AD;
}
body > #root > section.nutrition > main > .contentBox > .boxLine.two > .box.tips {
  background-color: #C47957;
}
body > #root > section.nutrition > main > .contentBox > .boxLine.two > .box > h5 {
  font-size: 56px;
  color: #FFF;
}
body > #root > section.nutrition > main > .contentBox > .boxLine.two > .box > small {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}
body > #root > section.nutrition > main > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > section.nutrition > main > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 15px;
  font-size: 20px;
  color: #003063;
}
body > #root > section.nutrition > main > .schedulesList {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  max-height: 50vh;
  overflow-y: scroll;
}
body > #root > section.nutrition > main > .schedulesList > .alertLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 13px;
  color: #DF0D0D;
}
body > #root > section.nutrition > main > .schedulesList > .scheduleLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.nutrition > main > .schedulesList > .scheduleLine:not(:first-child) {
  margin-top: 5px;
}
body > #root > section.nutrition > main > .schedulesList > .scheduleLine > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.nutrition > main > .schedulesList > .scheduleLine > div.date,
body > #root > section.nutrition > main > .schedulesList > .scheduleLine > div.nutritionist {
  width: 25%;
}
body > #root > section.nutrition > main > .schedulesList > .scheduleLine > div.meals {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section.nutrition > main > .schedulesList > .scheduleLine > div > small {
  font-size: 10px;
  font-weight: 600;
}
body > #root > section.nutrition > main > .schedulesList > .scheduleLine > div > span {
  font-size: 13px;
}
