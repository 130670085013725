/* Font Family */
/* Borda arredondada */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWuaabWmT.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWubEbWmT.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWub2bWmT.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWuYjammT.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWuZtammT.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyXsovBJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyWyovBJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyVVpfBJ.ttf) format('truetype');
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}
body {
  font-size: 14px;
  color: #333;
}
body * {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}
body a,
body a:visited,
body a:focus {
  text-decoration: none;
  color: #003063;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body a:hover,
body a:visited:hover,
body a:focus:hover {
  color: #001730;
}
body a.dec:hover,
body a:visited.dec:hover,
body a:focus.dec:hover {
  text-decoration: underline;
}
body h1,
body h2,
body h3,
body h4 {
  margin: 0;
}
body > #root {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  min-height: 100vh;
}
body > #root > section > main > .headerBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 52px;
  right: 155px;
  width: 40%;
  z-index: 9999;
}
body > #root > section > main > .headerBox > .buttons {
  margin-right: 10px;
  display: flex;
}
body > #root > section > main > .headerBox > .buttons > *:not(:first-child) {
  margin-left: 10px;
}
body > #root > section > main > .headerBox > .buttons > a.btn {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 9px 15px;
  font-size: 12px;
  color: #666;
  background-color: #F0F0F0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
body > #root > section > main > .headerBox > .buttons > a.btn > span:not(:first-child) {
  margin-left: 5px;
}
body > #root > section > main > .headerBox > .buttons > a.btn.back {
  color: rgba(102, 102, 102, 0.7);
  background-color: #d7d7d7;
}
body > #root > section > main > .headerBox > .buttons > a.btn.back:hover {
  color: #333;
  background-color: #cacaca;
}
::-webkit-scrollbar {
  width: 3px;
  background: #F0F0F0;
}
::-webkit-scrollbar-thumb {
  background: #D0D0D0;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: none;
}
::-webkit-scrollbar-corner {
  background: #000;
}
body > #root > .messageAlert {
  position: absolute;
  top: -100%;
  right: 10px;
  padding: 15px;
  color: rgba(255, 255, 255, 0.9);
  background-color: #666;
  border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -webkit-transition: all 3s ease-in-out;
  -moz-transition: all 3s ease-in-out;
  -o-transition: all 3s ease-in-out;
  -ms-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
}
body > #root > .messageAlert.error {
  background-color: #EA4335;
}
body > #root > .messageAlert.success {
  background-color: #197e19;
}
body > #root > .messageAlert.info {
  background-color: #28639f;
}
body > #root > .messageAlert.in {
  top: 0;
}
body > #root > section {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 140px 20px 0;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  background: url('../../../assets/images/bkg2.jpg') #FFF repeat;
}
body > #root > section > main > .noData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 150px;
  font-weight: 500;
  color: #EA4335;
}
@media screen and (max-width: 991px) {
  body > #root > section {
    width: 100vw;
  }
  body > #root > section > aside {
    display: none !important;
  }
  body > #root > section > main {
    padding: 20px !important;
    height: calc(100vh - 125px);
    /* Fallback for browsers that do not support Custom Properties */
    height: calc((var(--vh, 1vh) * 100) - 125px);
    min-height: 100%;
  }
  body > #root > section > main > .header > h1,
  body > #root > section > main > h1 {
    font-size: 1.2rem;
  }
}
