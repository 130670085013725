/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > .saving,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > .saving {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  font-size: 14px;
  color: #156815;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .error,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .error {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  font-size: 12px;
  color: #d62516;
  background-color: rgba(234, 67, 53, 0.2);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group:not(:first-child),
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group:not(:first-child) {
  margin-left: 10px;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > input,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > input,
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > select,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > select {
  width: 100%;
  padding: 10px;
  background-color: #FFF;
  border: 1px solid #C0C0C0;
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > input:focus,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > input:focus,
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > select:focus,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > select:focus {
  border-color: #666;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > input.fileHide,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > input.fileHide,
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > select.fileHide,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > select.fileHide {
  display: none;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > .checkbox,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > .checkbox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-top: 14px;
  height: 100%;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > .checkbox > input,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > .checkbox > input {
  margin: 0 0 0 10px;
  padding: 0;
  width: 1em;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > .attachmentBox,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > .attachmentBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > .attachmentBox > .attachment,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > .attachmentBox > .attachment {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px;
  cursor: pointer;
  background-color: #F0F0F0;
  border: none;
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
  border-radius: 6px 0 0 6px;
  -moz-border-radius: 6px 0 0 6px;
  -webkit-border-radius: 6px 0 0 6px;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > .attachmentBox > .attachment.active,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > .attachmentBox > .attachment.active {
  background-color: #FFF;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .input-group > .attachmentBox > .remove,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .input-group > .attachmentBox > .remove {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  color: #FFF;
  cursor: pointer;
  background-color: #EA4335;
  border: none;
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
  border-radius: 0 6px 6px 0;
  -moz-border-radius: 0 6px 6px 0;
  -webkit-border-radius: 0 6px 6px 0;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .btns,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .btns > button,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .btns > button:not(:first-child),
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .btns > button#btnAdd,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .btns > button#btnAdd {
  background-color: #22A822;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .btns > button#btnAdd:hover,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .btns > button#btnAdd:hover {
  background-color: #197e19;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .btns > button#btnCancel,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > .form-group > .btns > button#btnCancel:hover,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > .form-group > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
body > #root > #modalBox.classSchoolTestsCreate > .modalBody > .modalContent > form > hr,
body > #root > #modalBox.classSchoolActivitiesCreate > .modalBody > .modalContent > form > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 20px 0 0;
  width: 100%;
  height: 2px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .loading,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .loading {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  font-size: 14px;
  color: #b04803;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 12px;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine:not(:first-child),
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine:not(:first-child) {
  margin-top: 3px;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine > *:not(:first-child),
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine > *:not(:first-child) {
  margin-left: 3px;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine > div,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 5px 10px;
  background-color: #F0F0F0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine > div.title,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine > div.title {
  width: 70%;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine > div.date,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine > div.date {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20%;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine > button,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  width: 10%;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine > button.btnView,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine > button.btnView {
  background-color: #2d70b3;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine > button.btnView:hover,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine > button.btnView:hover {
  color: #FFF;
  background-color: #23568a;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine > button.btnDelete,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine > button.btnDelete {
  background-color: #DF0D0D;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .testLine > button.btnDelete:hover,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .testLine > button.btnDelete:hover {
  color: #FFF;
  background-color: #af0a0a;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .testsList > .alertInfo,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .testsList > .alertInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #EA4335;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .infos,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 0 15px;
  font-size: 13px;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .infos > p,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .infos > p {
  margin: 0;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .infos > p:last-child,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .infos > p:last-child {
  margin-top: 10px;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .infos > p.alert,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .infos > p.alert {
  font-weight: 500;
  color: #a81d11;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .infos > p > span,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .infos > p > span {
  font-weight: 600;
  font-style: italic;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .btns,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .btns > button,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .btns > button:not(:first-child),
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .btns > button#btnDelete,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .btns > button#btnDelete {
  background-color: #EA4335;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .btns > button#btnDelete:hover,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .btns > button#btnDelete:hover {
  background-color: #d62516;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .btns > button#btnCancel,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .btns > button#btnCancel {
  background-color: #666;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .btns > button#btnCancel:hover,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .btns > button#btnCancel:hover {
  background-color: #4d4d4d;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .removeBox > .removing,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .removeBox > .removing {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  font-size: 14px;
  color: #bf2114;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .btns,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .btns > button,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .btns > button:not(:first-child),
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .btns > button#btnCreate,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .btns > button#btnCreate {
  background-color: #23568a;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .btns > button#btnCreate:hover,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .btns > button#btnCreate:hover {
  background-color: #193d61;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .btns > button#btnCancel,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.classSchoolTestsList > .modalBody > .modalContent > .btns > button#btnCancel:hover,
body > #root > #modalBox.classSchoolActivitiesList > .modalBody > .modalContent > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > h3,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > h3 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 800;
  color: #003063;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > .desc,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > .desc {
  font-size: 12px;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > hr,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 20px 0 10px;
  width: 100%;
  height: 2px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > div,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > div {
  margin-top: 10px;
  font-size: 12px;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > .attachments,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > .attachments {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > .attachments > button,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > .attachments > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  width: 50%;
  height: 35px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #0ea271;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > .attachments > button:not(:first-child),
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > .attachments > button:not(:first-child) {
  margin-left: 20px;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > .attachments > button:hover,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > .attachments > button:hover {
  color: #FFF;
  background-color: #0a7350;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > .attachments > button:disabled,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > .attachments > button:disabled {
  cursor: default;
  background-color: #999;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > .attachments > button.down,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > .attachments > button.down {
  color: #FFF;
  background-color: #e25d04;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .testsInfos > .attachments > button.down > svg,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .testsInfos > .attachments > button.down > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .btns,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .btns > button,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .btns > button:not(:first-child),
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .btns > button#btnCancel,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.classSchoolTestsView > .modalBody > .modalContent > .btns > button#btnCancel:hover,
body > #root > #modalBox.classSchoolActivitiesView > .modalBody > .modalContent > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
