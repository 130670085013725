/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group > .input-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 10px;
  width: 100%;
}
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group > .input-group > label {
  margin-bottom: 3px;
}
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group > .input-group > input {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 13px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group > .btns > button#btnAdd {
  background-color: #22A822;
}
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group > .btns > button#btnAdd:hover {
  background-color: #197e19;
}
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group > .btns > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.category > .modalBody > .modalContent > form > .form-group > .btns > button#btnCancel:hover {
  background-color: #d62516;
}
body > #root > #modalBox.measure > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0;
  font-size: 13px;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .infos > p {
  margin: 5px 0;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .btns > button#btnDelete {
  background-color: #EA4335;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .btns > button#btnDelete:hover {
  background-color: #d62516;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .btns > button#btnCancel {
  background-color: #666;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .btns > button#btnCancel:hover {
  background-color: #4d4d4d;
}
