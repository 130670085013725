/* Font Family */
/* Borda arredondada */
body > #root > section.parentsNutritionTip > main > h2 {
  margin-bottom: 10px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 26px;
  font-weight: 900;
  color: #003063;
}
body > #root > section.parentsNutritionTip > main > small {
  font-size: 12px;
  color: #666;
}
body > #root > section.parentsNutritionTip > main > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0;
  width: 100%;
  height: 2px;
  background-color: #D0D0D0;
  border: none;
}
