/* Font Family */
/* Borda arredondada */
body > #root > section > main .headerBoxSearchAndForm {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 52px;
  right: 95px;
  width: 40%;
  z-index: 9999;
}
body > #root > section > main .headerBoxSearchAndForm > .searchBox {
  top: 52px;
  right: 255px;
  width: 200px;
  z-index: 9999;
  display: flex;
  align-items: center;
}
body > #root > section > main .headerBoxSearchAndForm > .searchBox > input {
  padding: 8px 30px 8px 10px;
  width: 100%;
  font-size: 0.8rem;
  color: #666;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  transition: all 0.3s ease;
}
body > #root > section > main .headerBoxSearchAndForm > .searchBox > input:focus {
  color: #333;
  background-color: #FFF;
  border-color: #666;
  outline: none;
}
body > #root > section > main .headerBoxSearchAndForm > .searchBox > input:disabled {
  color: #D0D0D0;
  background-color: #F0F0F0;
  border-color: #D0D0D0;
}
body > #root > section > main .headerBoxSearchAndForm > .searchBox > input:disabled::placeholder {
  color: #C0C0C0;
}
body > #root > section > main .headerBoxSearchAndForm > .searchBox > button {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 30px;
  background-color: transparent;
  border: none;
  color: #EA4335;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons {
  margin-right: 10px;
  display: flex;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > *:not(:first-child) {
  margin-left: 10px;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > button,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a.btn,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 9px 15px;
  font-size: 12px;
  color: #666;
  background-color: #F0F0F0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > button > span:not(:first-child),
body > #root > section > main .headerBoxSearchAndForm > .buttons > a.btn > span:not(:first-child),
body > #root > section > main .headerBoxSearchAndForm > .buttons > a > button > span:not(:first-child) {
  margin-left: 5px;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > button.info,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a.btn.info,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a > button.info {
  background-color: #C0C0C0;
  color: white;
  margin-right: 15px;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > button.info:hover,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a.btn.info:hover,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a > button.info:hover {
  background-color: #6c757d;
  color: white;
  border-color: #000000;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > button.add,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a.btn.add,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a > button.add {
  color: rgba(255, 255, 255, 0.7);
  background-color: #197e19;
  border-color: #115311;
  margin-right: 15px;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > button.add:hover,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a.btn.add:hover,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a > button.add:hover {
  color: #FFF;
  background-color: #156815;
  border-color: #000000;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > button.other,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a.btn.other,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a > button.other {
  color: rgba(255, 255, 255, 0.7);
  background-color: #c95203;
  border-color: #973e02;
  margin-right: 15px;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > button.other:hover,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a.btn.other:hover,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a > button.other:hover {
  color: #FFF;
  background-color: #b04803;
  border-color: #321501;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > button.back,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a.btn.back,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a > button.back {
  color: rgba(102, 102, 102, 0.7);
  background-color: #d7d7d7;
}
body > #root > section > main .headerBoxSearchAndForm > .buttons > button.back:hover,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a.btn.back:hover,
body > #root > section > main .headerBoxSearchAndForm > .buttons > a > button.back:hover {
  color: #333;
  background-color: #cacaca;
}
body > #root > section > main .headerBoxSearchAndForm > .schools,
body > #root > section > main .headerBoxSearchAndForm > .students,
body > #root > section > main .headerBoxSearchAndForm > .teachers,
body > #root > section > main .headerBoxSearchAndForm > .classes,
body > #root > section > main .headerBoxSearchAndForm > .users,
body > #root > section > main .headerBoxSearchAndForm > .types {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-right: 10px;
}
body > #root > section > main .headerBoxSearchAndForm > .schools > select,
body > #root > section > main .headerBoxSearchAndForm > .students > select,
body > #root > section > main .headerBoxSearchAndForm > .teachers > select,
body > #root > section > main .headerBoxSearchAndForm > .classes > select,
body > #root > section > main .headerBoxSearchAndForm > .users > select,
body > #root > section > main .headerBoxSearchAndForm > .types > select {
  padding: 8px 30px 8px 10px;
  width: 100%;
  font-size: 12px;
  color: #666;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  transition: all 0.3s ease;
}
body > #root > section > main .headerBoxSearchAndForm > .teachers,
body > #root > section > main .headerBoxSearchAndForm > .users {
  width: 200px;
}
body > #root > section > main .headerBoxSearchAndForm > .schools {
  width: 300px;
}
body > #root > section > main .headerBoxSearchAndForm > .students {
  width: 300px;
}
body > #root > section > main .headerBoxSearchAndForm > .classes {
  width: 110px;
}
body > #root > section > main .headerBoxSearchAndForm > .types {
  width: 140px;
}
