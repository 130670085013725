/* Font Family */
/* Borda arredondada */
body > #root > section.teacher > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.teacher > main > .contentBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
}
body > #root > section.teacher > main > .contentBox > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  width: 100%;
}
body > #root > section.teacher > main > .contentBox > .header:not(:first-child) {
  margin-top: 20px;
}
body > #root > section.teacher > main > .contentBox > .header > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  width: calc(20% - 12px);
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section.teacher > main > .contentBox > .header > .box:not(:first-child):not(:nth-child(5n+6)) {
  margin-left: 15px;
}
body > #root > section.teacher > main > .contentBox > .header > .box:nth-child(n+6) {
  margin-top: 15px;
}
body > #root > section.teacher > main > .contentBox > .header > .box > h5 {
  margin: 0;
  font-size: 36px;
}
body > #root > section.teacher > main > .contentBox > .header > .box > small {
  font-size: 11px;
  color: rgba(51, 51, 51, 0.7);
  text-align: center;
}
body > #root > section.teacher > main > .contentBox > .header > .box.testsActivitiesBox {
  background-color: #4383AD;
}
body > #root > section.teacher > main > .contentBox > .header > .box.testsActivitiesBox:hover {
  background-color: #356788;
}
body > #root > section.teacher > main > .contentBox > .header > .box.notesOthersBox {
  background-color: rgba(251, 103, 4, 0.7);
}
body > #root > section.teacher > main > .contentBox > .header > .box.notesOthersBox:hover {
  background-color: rgba(201, 82, 3, 0.7);
}
body > #root > section.teacher > main > .contentBox > .header > .box.lessonPlanningBox {
  background-color: #36A952;
}
body > #root > section.teacher > main > .contentBox > .header > .box.lessonPlanningBox:hover {
  background-color: #2a823f;
}
body > #root > section.teacher > main > .contentBox > .header > .box.testsActivitiesBox,
body > #root > section.teacher > main > .contentBox > .header > .box.notesOthersBox,
body > #root > section.teacher > main > .contentBox > .header > .box.lessonPlanningBox {
  color: #FFF;
}
body > #root > section.teacher > main > .contentBox > .header > .box.testsActivitiesBox > small,
body > #root > section.teacher > main > .contentBox > .header > .box.notesOthersBox > small,
body > #root > section.teacher > main > .contentBox > .header > .box.lessonPlanningBox > small {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}
body > #root > section.teacher > main > .contentBox > .header > .box.testsActivitiesBox:hover,
body > #root > section.teacher > main > .contentBox > .header > .box.notesOthersBox:hover,
body > #root > section.teacher > main > .contentBox > .header > .box.lessonPlanningBox:hover {
  color: #FFF;
}
body > #root > section.teacher > main > .contentBox > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > section.teacher > main > .contentBox > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 15px;
  font-size: 20px;
  color: #003063;
}
body > #root > section.teacher > main > .contentBox > .lastActivities,
body > #root > section.teacher > main > .contentBox > .lastTests {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  max-height: 26vh;
  overflow-y: scroll;
}
body > #root > section.teacher > main > .contentBox > .lastActivities > .alertLine,
body > #root > section.teacher > main > .contentBox > .lastTests > .alertLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 13px;
  color: #DF0D0D;
}
body > #root > section.teacher > main > .contentBox > .lastActivities > .activityLine,
body > #root > section.teacher > main > .contentBox > .lastTests > .activityLine,
body > #root > section.teacher > main > .contentBox > .lastActivities > .testLine,
body > #root > section.teacher > main > .contentBox > .lastTests > .testLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.teacher > main > .contentBox > .lastActivities > .activityLine:not(:first-child),
body > #root > section.teacher > main > .contentBox > .lastTests > .activityLine:not(:first-child),
body > #root > section.teacher > main > .contentBox > .lastActivities > .testLine:not(:first-child),
body > #root > section.teacher > main > .contentBox > .lastTests > .testLine:not(:first-child) {
  margin-top: 5px;
}
body > #root > section.teacher > main > .contentBox > .lastActivities > .activityLine > div,
body > #root > section.teacher > main > .contentBox > .lastTests > .activityLine > div,
body > #root > section.teacher > main > .contentBox > .lastActivities > .testLine > div,
body > #root > section.teacher > main > .contentBox > .lastTests > .testLine > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 25%;
}
body > #root > section.teacher > main > .contentBox > .lastActivities > .activityLine > div > small,
body > #root > section.teacher > main > .contentBox > .lastTests > .activityLine > div > small,
body > #root > section.teacher > main > .contentBox > .lastActivities > .testLine > div > small,
body > #root > section.teacher > main > .contentBox > .lastTests > .testLine > div > small {
  font-size: 10px;
  font-weight: 600;
}
body > #root > section.teacher > main > .contentBox > .lastActivities > .activityLine > div > span,
body > #root > section.teacher > main > .contentBox > .lastTests > .activityLine > div > span,
body > #root > section.teacher > main > .contentBox > .lastActivities > .testLine > div > span,
body > #root > section.teacher > main > .contentBox > .lastTests > .testLine > div > span {
  font-size: 13px;
}
body > #root > section.teacher > main > .contentBox > .lastActivities > .activityLine > div.school,
body > #root > section.teacher > main > .contentBox > .lastTests > .activityLine > div.school,
body > #root > section.teacher > main > .contentBox > .lastActivities > .testLine > div.school,
body > #root > section.teacher > main > .contentBox > .lastTests > .testLine > div.school {
  width: 30%;
}
body > #root > section.teacher > main > .contentBox > .lastActivities > .activityLine > div.classs,
body > #root > section.teacher > main > .contentBox > .lastTests > .activityLine > div.classs,
body > #root > section.teacher > main > .contentBox > .lastActivities > .testLine > div.classs,
body > #root > section.teacher > main > .contentBox > .lastTests > .testLine > div.classs {
  width: 15%;
}
body > #root > section.teacher > main > .contentBox > .lastActivities > .activityLine > div.date,
body > #root > section.teacher > main > .contentBox > .lastTests > .activityLine > div.date,
body > #root > section.teacher > main > .contentBox > .lastActivities > .testLine > div.date,
body > #root > section.teacher > main > .contentBox > .lastTests > .testLine > div.date {
  width: 15%;
}
body > #root > section.teacher > main > .contentBox > .lastActivities > .activityLine > div.activity,
body > #root > section.teacher > main > .contentBox > .lastTests > .activityLine > div.activity,
body > #root > section.teacher > main > .contentBox > .lastActivities > .testLine > div.activity,
body > #root > section.teacher > main > .contentBox > .lastTests > .testLine > div.activity {
  width: 40%;
}
