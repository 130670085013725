/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > .saving {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  font-size: 14px;
  color: #156815;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-right: 15px;
  overflow-y: scroll;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > h3 {
  margin: 0 0 15px;
  width: 100%;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #003063;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > hr {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  min-height: 1px;
  background-color: #F0F0F0;
  border: none;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > .form-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > .form-group > .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 10px;
  width: 100%;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > .form-group > .inputLabel:not(:first-child) {
  margin-left: 10px;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > .form-group > .inputLabel > label {
  margin-bottom: 3px;
  font-size: 11px;
  font-weight: 600;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > .form-group > .inputLabel > input {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 12px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > .form-group > .inputLabel > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 13px;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > .form-group > .inputLabel > select:hover,
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > .form-group > .inputLabel > select:focus {
  border-color: #333;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > .form-group > .inputLabel > select:disabled {
  background-color: #F0F0F0;
  border-color: #C0C0C0;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > form > .form-group > .inputLabel > textarea {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 13px;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > .buttons > .error {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 10px;
  font-size: 12px;
  color: #d62516;
  background-color: rgba(234, 67, 53, 0.2);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > .buttons > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > .buttons > button#btnAdd {
  background-color: #22A822;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > .buttons > button#btnAdd:hover {
  background-color: #197e19;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > .buttons > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.transAddEdit > .modalBody > .modalContent > .buttons > button#btnCancel:hover {
  background-color: #d62516;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .originSchool,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .destinySchool,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .student,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 10px;
  width: 100%;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .originSchool > b,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .destinySchool > b,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .student > b,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status > b {
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 600;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .originSchool > span,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .destinySchool > span,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .student > span,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status > span {
  font-size: 14px;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .originSchool {
  margin-bottom: 30px;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 20px;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status > b {
  margin-bottom: 20px;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status > .statusBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status > .statusBox > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px;
  font-size: 12px;
  color: #666;
  cursor: pointer;
  background-color: #D0D0D0;
  border: none;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status > .statusBox > button:first-child {
  margin-right: 10px;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status > .statusBox > button#btnApproved:hover,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status > .statusBox > button#btnApproved.active {
  color: #FFF;
  background-color: rgba(25, 126, 25, 0.8);
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status > .statusBox > button#btnRefused:hover,
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .status > .statusBox > button#btnRefused.active {
  color: #FFF;
  background-color: rgba(214, 37, 22, 0.7);
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > hr {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  min-height: 1px;
  background-color: #F0F0F0;
  border: none;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .buttons > .error {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 10px;
  font-size: 12px;
  color: #d62516;
  background-color: rgba(234, 67, 53, 0.2);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .buttons > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .buttons > button#btnAdd {
  background-color: #22A822;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .buttons > button#btnAdd:hover {
  background-color: #197e19;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .buttons > button#btnCancel {
  background-color: #EA4335;
}
body > #root > #modalBox.transStatus > .modalBody > .modalContent > .buttons > button#btnCancel:hover {
  background-color: #d62516;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0;
  font-size: 13px;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent > .infos > p {
  margin: 5px 0;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent > .infos > p.alert {
  font-weight: 500;
  color: #a81d11;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent > .btns > button#btnDelete {
  background-color: #EA4335;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent > .btns > button#btnDelete:hover {
  background-color: #d62516;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent > .btns > button#btnCancel {
  background-color: #666;
}
body > #root > #modalBox.transDelete > .modalBody > .modalContent > .btns > button#btnCancel:hover {
  background-color: #4d4d4d;
}
