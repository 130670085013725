/* Font Family */
/* Borda arredondada */
body > #root > #modalBox {
  position: fixed;
  top: 0;
  left: 0;
  padding: 50px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(102, 102, 102, 0.9);
  z-index: 99999;
}
body > #root > #modalBox .modalBody {
  margin: 0 auto;
  max-width: 500px;
  background-color: #FFF;
  border-radius: 16px 16px 15px 15px;
  -moz-border-radius: 16px 16px 15px 15px;
  -webkit-border-radius: 16px 16px 15px 15px;
}
body > #root > #modalBox .modalBody > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  color: #FFF;
  background-color: #003063;
  border-bottom: 1px solid #F0F0F0;
  border-radius: 15px 15px 0 0;
  -moz-border-radius: 15px 15px 0 0;
  -webkit-border-radius: 15px 15px 0 0;
}
body > #root > #modalBox .modalBody > header > h3 {
  margin: 0;
  padding: 18px;
  font-size: 18px;
}
body > #root > #modalBox .modalBody > header > button.close {
  padding: 18px;
  color: rgba(255, 255, 255, 0.7);
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox .modalBody > header > button.close:hover {
  color: rgba(255, 255, 255, 0.9);
}
body > #root > #modalBox .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px;
  max-height: 80vh;
  overflow: visible;
}
body > #root > #modalBox .modalBody > .modalContent > form > .form-group:not(:first-child),
body > #root > #modalBox .modalBody > .modalContent > form > .input-group:not(:first-child),
body > #root > #modalBox .modalBody > .modalContent > form > .inputLabel:not(:first-child) {
  margin-top: 20px;
}
body > #root > #modalBox .modalBody > .modalContent > form > .form-group.no-margin,
body > #root > #modalBox .modalBody > .modalContent > form > .input-group.no-margin,
body > #root > #modalBox .modalBody > .modalContent > form > .inputLabel.no-margin {
  margin: 0 !important;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel,
body > #root > #modalBox .modalBody > .modalContent > form .input-group,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group > label,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel > label,
body > #root > #modalBox .modalBody > .modalContent > form .input-group > label,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel > label {
  font-size: 0.8em;
  font-weight: 600;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group > label > i,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel > label > i,
body > #root > #modalBox .modalBody > .modalContent > form .input-group > label > i,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel > label > i {
  font-size: 0.75em;
  color: #999;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group input,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel input,
body > #root > #modalBox .modalBody > .modalContent > form .input-group input,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel input,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group select,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel select,
body > #root > #modalBox .modalBody > .modalContent > form .input-group select,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel select,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group textarea,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel textarea,
body > #root > #modalBox .modalBody > .modalContent > form .input-group textarea,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #999;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group input.upC,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel input.upC,
body > #root > #modalBox .modalBody > .modalContent > form .input-group input.upC,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel input.upC,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group select.upC,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel select.upC,
body > #root > #modalBox .modalBody > .modalContent > form .input-group select.upC,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel select.upC,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group textarea.upC,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel textarea.upC,
body > #root > #modalBox .modalBody > .modalContent > form .input-group textarea.upC,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel textarea.upC {
  text-transform: uppercase;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group input.error,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel input.error,
body > #root > #modalBox .modalBody > .modalContent > form .input-group input.error,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel input.error,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group select.error,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel select.error,
body > #root > #modalBox .modalBody > .modalContent > form .input-group select.error,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel select.error,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group textarea.error,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel textarea.error,
body > #root > #modalBox .modalBody > .modalContent > form .input-group textarea.error,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel textarea.error {
  background-color: #fdefee;
  border: 1px solid #d62516;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group input.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel input.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .input-group input.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel input.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group select.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel select.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .input-group select.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel select.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group textarea.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel textarea.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .input-group textarea.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel textarea.error:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group input.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel input.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .input-group input.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel input.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group select.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel select.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .input-group select.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel select.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group textarea.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel textarea.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .input-group textarea.error:focus,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel textarea.error:focus {
  background-color: #fef8f7;
  border-color: #a81d11;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group input:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel input:hover,
body > #root > #modalBox .modalBody > .modalContent > form .input-group input:hover,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel input:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group select:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel select:hover,
body > #root > #modalBox .modalBody > .modalContent > form .input-group select:hover,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel select:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group textarea:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel textarea:hover,
body > #root > #modalBox .modalBody > .modalContent > form .input-group textarea:hover,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel textarea:hover,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group input:focus,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel input:focus,
body > #root > #modalBox .modalBody > .modalContent > form .input-group input:focus,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel input:focus,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group select:focus,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel select:focus,
body > #root > #modalBox .modalBody > .modalContent > form .input-group select:focus,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel select:focus,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group textarea:focus,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel textarea:focus,
body > #root > #modalBox .modalBody > .modalContent > form .input-group textarea:focus,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel textarea:focus {
  border-color: #333;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w50,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w50,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w50,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w50 {
  width: 50px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w100,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w100,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w100,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w100 {
  width: 100px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w140,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w140,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w140,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w140 {
  width: 140px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w150,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w150,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w150,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w150 {
  width: 150px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w200,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w200,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w200,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w200 {
  width: 200px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w220,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w220,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w220,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w220 {
  width: 220px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w250,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w250,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w250,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w250 {
  width: 250px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w260,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w260,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w260,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w260 {
  width: 260px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w300,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w300,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w300,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w300 {
  width: 300px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w350,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w350,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w350,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w350 {
  width: 350px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w400,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w400,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w400,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w400 {
  width: 400px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w450,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w450,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w450,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w450 {
  width: 450px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w500,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w500,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w500,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w500 {
  width: 500px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.w550,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.w550,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.w550,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.w550 {
  width: 550px;
}
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .input-group.mg-none,
body > #root > #modalBox .modalBody > .modalContent > form .form-group > .inputLabel.mg-none,
body > #root > #modalBox .modalBody > .modalContent > form .input-group.mg-none,
body > #root > #modalBox .modalBody > .modalContent > form .inputLabel.mg-none {
  margin: 0;
}
body > #root > #modalBox .modalBody > .modalContent > .alert {
  margin-top: 15px;
  font-size: 0.8em;
  font-weight: bold;
  color: #cc0000;
}
body > #root > #modalBox .modalBody > .modalContent > footer {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin: 30px -15px -15px;
  padding: 15px;
  background-color: #e3e3e3;
}
body > #root > #modalBox .modalBody > .modalContent > footer > button {
  padding: 10px 15px;
  color: #FFF;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox .modalBody > .modalContent > footer > button#btnSave {
  background-color: #197e19;
}
body > #root > #modalBox .modalBody > .modalContent > footer > button#btnSave:hover {
  background-color: #115311;
}
body > #root > #modalBox .modalBody > .modalContent > footer > button#btnDelete {
  background-color: #d62516;
}
body > #root > #modalBox .modalBody > .modalContent > footer > button#btnDelete:hover {
  background-color: #a81d11;
}
body > #root > #modalBox .modalBody > .modalContent > footer > button#btnCancel {
  background-color: #8c8c8c;
}
body > #root > #modalBox .modalBody > .modalContent > footer > button#btnCancel:hover {
  background-color: #737373;
}
@media screen and (max-width: 991px) {
  body > #root > #modalBox {
    padding: 25px;
  }
}
