/* Font Family */
/* Borda arredondada */
body > #root > section.secEducAttendance > main > .attendance {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin-bottom: 20px;
}
