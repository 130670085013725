/* Font Family */
/* Borda arredondada */
body > #root > section.dash > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.dash > main > .contentBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding-bottom: 20px;
  width: 100%;
}
body > #root > section.dash > main > .contentBox > .headerLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.dash > main > .contentBox > .headerLine > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  width: calc(24.65% - 10px);
  min-height: 200px;
  color: #FFF;
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section.dash > main > .contentBox > .headerLine > .box:not(:first-child) {
  margin-left: 30px;
}
body > #root > section.dash > main > .contentBox > .headerLine > .box > h5 {
  margin: 0;
  font-size: 50px;
}
body > #root > section.dash > main > .contentBox > .headerLine > .box > small {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}
body > #root > section.dash > main > .contentBox > .headerLine > .box.school {
  background-color: #36A952;
}
body > #root > section.dash > main > .contentBox > .headerLine > .box.students {
  background-color: #4383AD;
}
body > #root > section.dash > main > .contentBox > .headerLine > .box.elementary {
  background-color: #C47957;
}
body > #root > section.dash > main > .contentBox > .boxLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  width: 100%;
}
body > #root > section.dash > main > .contentBox > .boxLine > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  width: calc(25% - 23px);
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section.dash > main > .contentBox > .boxLine > .box:not(:first-child) {
  margin-left: 30px;
}
body > #root > section.dash > main > .contentBox > .boxLine > .box > h5 {
  font-size: 34px;
}
body > #root > section.dash > main > .contentBox > .boxLine > .box > small {
  font-size: 11px;
  color: rgba(51, 51, 51, 0.7);
  text-align: center;
}
body > #root > section.dash > main > .contentBox > .headerLine > .box > h5,
body > #root > section.dash > main > .contentBox > .boxLine > .box > h5 {
  margin: 0;
}
body > #root > section.dash > main > .contentBox > h3 {
  position: relative;
  display: inline-block;
  margin: 30px 0 20px;
  width: 100%;
  color: #666;
  font-size: 20px;
  overflow: hidden;
}
body > #root > section.dash > main > .contentBox > h3::after {
  position: absolute;
  content: "";
  top: 50%;
  margin-top: 1px;
  margin-left: 10px;
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #D0D0D0;
  box-sizing: border-box;
}
