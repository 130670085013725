/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.newRequest > .modalBody {
  max-width: 700px;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .formGroup {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .formGroup:not(:first-child) {
  margin-top: 20px;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .formGroup > .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .formGroup > .inputLabel.product {
  width: 70%;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .formGroup > .inputLabel.quantity {
  width: 18%;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .formGroup > .inputLabel > label {
  font-size: 12px;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .formGroup > button {
  padding: 10px 15px;
  color: #FFF;
  cursor: pointer;
  background-color: #EA4335;
  border: none;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  width: 100%;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-top: 30px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .buttons > button.add {
  width: 75%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #c95203;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .buttons > button.add:hover {
  color: #FFF;
  background-color: #973e02;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .buttons > button.save {
  margin-left: 10px;
  width: 25%;
  color: rgba(255, 255, 255, 0.7);
  background-color: #197e19;
}
body > #root > #modalBox.newRequest > .modalBody > .modalContent > .buttons > button.save:hover {
  color: #FFF;
  background-color: #156815;
}
