/* Font Family */
/* Borda arredondada */
body > #root > .secEducMap > main {
  padding-bottom: 20px;
}
body > #root > .secEducMap > main > .mapContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > .secEducMap > main > .mapContent > .loading > .icon {
  max-width: 200px;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c {
  padding: 15px !important;
  overflow: visible !important;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-chr {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 9999;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-chr > button {
  padding: 3px !important;
  width: auto !important;
  height: auto !important;
  background-color: #FFF !important;
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-chr > button > span {
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0.5;
  filter: alpha(opacity=50);
  /* For IE8 and earlier */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-chr > button:hover > span {
  opacity: 0.7;
  filter: alpha(opacity=70);
  /* For IE8 and earlier */
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-d > div {
  min-width: 300px;
  max-width: 300px;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-d > div > .name {
  margin-bottom: 5px;
  font-size: 14px;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-d > div > .name.school {
  color: #004678;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-d > div > .name.student {
  color: #236f36;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-d > div > .content {
  font-size: 11px;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-d > div > .content > p {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin: 8px 0;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-d > div > .content > p > b {
  margin-right: 5px;
  font-weight: 600;
}
body > #root > .secEducMap > main > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-d > div > .content > hr {
  display: block;
  margin: 15px 0;
  width: 100%;
  height: 1px;
  background-color: #999;
  border: none;
}
