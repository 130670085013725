/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.map > .modalBody {
  max-width: 700px;
}
body > #root > #modalBox > .modalBody > .modalContent > .mapContent {
  width: 100%;
  height: 500px;
}
body > #root > #modalBox > .modalBody > .modalContent > .mapContent > .loading {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding-bottom: 50px;
  height: 100%;
}
body > #root > #modalBox > .modalBody > .modalContent > .mapContent > .loading > .icon {
  max-width: 200px;
}
body > #root > #modalBox > .modalBody > .modalContent > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c {
  padding: 15px !important;
  overflow: visible !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-chr {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 9999;
}
body > #root > #modalBox > .modalBody > .modalContent > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-chr > button {
  padding: 3px !important;
  width: auto !important;
  height: auto !important;
  background-color: #FFF !important;
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
body > #root > #modalBox > .modalBody > .modalContent > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-chr > button > span {
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0.5;
  filter: alpha(opacity=50);
  /* For IE8 and earlier */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox > .modalBody > .modalContent > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-chr > button:hover > span {
  opacity: 0.7;
  filter: alpha(opacity=70);
  /* For IE8 and earlier */
}
body > #root > #modalBox > .modalBody > .modalContent > .mapContent > div > .gm-style > div > div:nth-child(2) > div > div > div > .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-d > div > .name {
  padding: 0 20px;
  font-size: 14px;
  text-align: center;
}
