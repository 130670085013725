/* Font Family */
/* Borda arredondada */
body > #root > section.transport > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.transport > main > .contentBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding-bottom: 20px;
  width: 100%;
}
body > #root > section.transport > main > .contentBox > .boxLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  width: 100%;
}
body > #root > section.transport > main > .contentBox > .boxLine:not(:first-child) {
  margin-top: 20px;
}
body > #root > section.transport > main > .contentBox > .boxLine > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  width: calc(33.33% - 14px);
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section.transport > main > .contentBox > .boxLine > .box:not(:first-child) {
  margin-left: 20px;
}
body > #root > section.transport > main > .contentBox > .boxLine > .box > h5 {
  margin: 0;
  font-size: 36px;
}
body > #root > section.transport > main > .contentBox > .boxLine > .box > small {
  font-size: 11px;
  color: rgba(51, 51, 51, 0.7);
  text-align: center;
}
body > #root > section.transport > main > .contentBox > .boxLine.two > .box {
  width: calc(50% - 10px);
}
body > #root > section.transport > main > .contentBox > .boxLine.two > .box.lines {
  background-color: #4383AD;
}
body > #root > section.transport > main > .contentBox > .boxLine.two > .box.vehicles {
  background-color: #C47957;
}
body > #root > section.transport > main > .contentBox > .boxLine.two > .box > h5 {
  font-size: 56px;
  color: #FFF;
}
body > #root > section.transport > main > .contentBox > .boxLine.two > .box > small {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}
