/* Font Family */
/* Borda arredondada */
body > #root > section.supDirection > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.supDirection > main > .contentBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding-bottom: 20px;
  width: 100%;
}
body > #root > section.supDirection > main > .contentBox > .boxLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  width: 100%;
}
body > #root > section.supDirection > main > .contentBox > .boxLine:not(:first-child) {
  margin-top: 20px;
}
body > #root > section.supDirection > main > .contentBox > .boxLine > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  width: calc(33.33% - 14px);
  color: #FFF;
  background-color: #999;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section.supDirection > main > .contentBox > .boxLine > .box:not(:first-child) {
  margin-left: 20px;
}
body > #root > section.supDirection > main > .contentBox > .boxLine > .box > h5 {
  margin: 0;
  font-size: 36px;
}
body > #root > section.supDirection > main > .contentBox > .boxLine > .box > small {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
}
body > #root > section.supDirection > main > .contentBox > .boxLine > .box.visits {
  background-color: #3c759b;
}
body > #root > section.supDirection > main > .contentBox > .boxLine > .box.previous {
  background-color: rgba(25, 126, 25, 0.8);
}
body > #root > section.supDirection > main > .contentBox > .boxLine > .box.next {
  background-color: rgba(201, 82, 3, 0.7);
}
body > #root > section.supDirection > main > .contentBox > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > section.supDirection > main > .contentBox > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 15px;
  font-size: 20px;
  color: #003063;
}
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  max-height: 56vh;
  overflow-y: scroll;
}
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm > .alertLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 13px;
  color: #DF0D0D;
}
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm > .termLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px;
  background-color: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm > .termLine:not(:first-child) {
  margin-top: 5px;
}
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm > .termLine > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 25%;
}
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm > .termLine > div > small {
  font-size: 10px;
  font-weight: 600;
}
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm > .termLine > div > span {
  margin: 0;
  width: 90%;
  white-space: nowrap;
  font-size: 13px;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis "...";
}
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm > .termLine > div.school {
  width: 34%;
}
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm > .termLine > div.date,
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm > .termLine > div.supervisor,
body > #root > section.supDirection > main > .contentBox > .nextVisitTerm > .termLine > div.reponsible {
  width: 22%;
}
