/* Font Family */
/* Borda arredondada */
body > #root > section > main > .headerBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 52px;
  right: 155px;
  width: 40%;
  z-index: 9999;
}
body > #root > section > main > .headerBox > .buttons {
  margin-right: 10px;
  display: flex;
}
body > #root > section > main > .headerBox > .buttons > *:not(:first-child) {
  margin-left: 10px;
}
body > #root > section > main > .headerBox > .buttons > a.btn {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 9px 15px;
  font-size: 12px;
  color: #666;
  background-color: #F0F0F0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
body > #root > section > main > .headerBox > .buttons > a.btn > span:not(:first-child) {
  margin-left: 5px;
}
body > #root > section > main > .headerBox > .buttons > a.btn.back {
  color: rgba(102, 102, 102, 0.7);
  background-color: #d7d7d7;
}
body > #root > section > main > .headerBox > .buttons > a.btn.back:hover {
  color: #333;
  background-color: #cacaca;
}
body > #root > .secEducCreatePermissions > main {
  padding-bottom: 20px;
}
body > #root > .secEducCreatePermissions > main > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: white;
  padding: 25px;
  max-width: 100%;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
body > #root > .secEducCreatePermissions > main > form > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > .secEducCreatePermissions > main > form > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > .secEducCreatePermissions > main > form > .header > .ruleBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > .secEducCreatePermissions > main > form > .header > .ruleBox > label {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}
body > #root > .secEducCreatePermissions > main > form > .header > .ruleBox > input.ruleName {
  padding: 7px 10px;
  width: 35%;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > .secEducCreatePermissions > main > form > .header > .ruleBox > input.ruleName:hover,
body > #root > .secEducCreatePermissions > main > form > .header > .ruleBox > input.ruleName:focus {
  border-color: #666;
}
body > #root > .secEducCreatePermissions > main > form > .header > button.saveBtn {
  padding: 8px 15px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  background-color: #22A822;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .secEducCreatePermissions > main > form > .header > button.saveBtn:hover {
  color: #FFF;
  background-color: #197e19;
}
body > #root > .secEducCreatePermissions > main > form > .permissions {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > h3 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #003063;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem:not(:nth-child(2)) {
  margin-top: 5px;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .title {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px 15px;
  font-weight: 600;
  color: #004485;
  cursor: pointer;
  background-color: rgba(210, 233, 255, 0.4);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .title > span {
  font-size: 16px;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .title:hover {
  color: #002a52;
  background-color: rgba(210, 233, 255, 0.8);
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .cpContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 15px 0;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .cpContent.hidden {
  display: none !important;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .cpContent > .lines {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding-top: 8px;
  padding-left: 15px;
  font-size: 12px;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .cpContent > .lines * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .cpContent > .lines label {
  cursor: pointer;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .cpContent > .lines label:last-child {
  margin-left: 20px;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .cpContent > .lines.all {
  margin-bottom: 10px;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .cpContent > .lines.sub {
  padding-left: 20px;
}
body > #root > .secEducCreatePermissions > main > form > .permissions > .cpItem > .cpContent > .lines.sub > .page {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 192px;
  font-weight: 500;
}
