/* Font Family */
/* Borda arredondada */
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > button,
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 9px 15px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #fb6704;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > button:hover,
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect > button:hover {
  color: #FFF;
  background-color: #e25d04;
}
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > button > span:not(:first-child),
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect > button > span:not(:first-child) {
  margin-left: 5px;
}
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > button.mult,
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect > button.mult {
  width: 40%;
}
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > button.apply,
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect > button.apply {
  background-color: #0ea271;
}
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > button.apply:hover,
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect > button.apply:hover {
  color: #FFF;
  background-color: #0c8a60;
}
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > button.cancel,
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect > button.cancel {
  background-color: rgba(223, 13, 13, 0.8);
}
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > button.cancel:hover,
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect > button.cancel:hover {
  color: #FFF;
  background-color: rgba(199, 12, 12, 0.8);
}
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  width: 40%;
}
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > .secEducCalendar > main > .headerBoxSearchAndForm > .multSelect > button.cancel {
  margin-left: 2px;
}
body > #root > .secEducCalendar > main > .calendar-container {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
  width: 100%;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: fixed;
  right: 20px;
  width: 33%;
  padding: 20px;
  height: 83vh;
  background-color: #FFF;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
}
body > #root > .secEducCalendar > main > .calendar-container > .rigth > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #d0d0d0;
  border: none;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 0;
  color: #666;
  cursor: pointer;
  background-color: transparent;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:not(:first-child) {
  margin-top: 5px;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover {
  color: #000;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.recess {
  background-color: #D64A34 !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.holiday {
  background-color: #FF2424 !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.teacherHoliday {
  background-color: #C2A53E !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.planning {
  background-color: #4E7FA7 !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.schoolMeeting {
  background-color: #A10B95 !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.continuingEducation {
  background-color: #E68CC5 !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.optionalHoliday {
  background-color: #F58041 !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.classCouncil {
  background-color: #9C6895 !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.schoolDays {
  background-color: #9FBE39 !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.weekend {
  background-color: #9F9F9F !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.pcdDay {
  background-color: #6EACAD !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item:hover > span.startEndSchoolYear {
  background-color: #009FE9 !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: 0.5rem;
  background-color: #d0d0d0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.recess {
  background-color: rgba(214, 74, 52, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.holiday {
  background-color: rgba(255, 36, 36, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.teacherHoliday {
  background-color: rgba(194, 165, 62, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.planning {
  background-color: rgba(78, 127, 167, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.schoolMeeting {
  background-color: rgba(161, 11, 149, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.continuingEducation {
  background-color: rgba(230, 140, 197, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.optionalHoliday {
  background-color: rgba(245, 128, 65, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.classCouncil {
  background-color: rgba(156, 104, 149, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.schoolDays {
  background-color: rgba(159, 190, 57, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.weekend {
  background-color: rgba(159, 159, 159, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.pcdDay {
  background-color: rgba(110, 172, 173, 0.6) !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .legend-item > span.startEndSchoolYear {
  background-color: rgba(0, 159, 233, 0.6) !important;
}
