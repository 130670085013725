/* Font Family */
/* Borda arredondada */
body > #root > #modalBox.category > .modalBody > .modalContent > .header,
body > #root > #modalBox.category > .modalBody > .modalContent > .categoryBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 12px;
}
body > #root > #modalBox.category > .modalBody > .modalContent > .header:not(:first-child),
body > #root > #modalBox.category > .modalBody > .modalContent > .categoryBox:not(:first-child) {
  margin-top: 3px;
}
body > #root > #modalBox.category > .modalBody > .modalContent > .header > .name,
body > #root > #modalBox.category > .modalBody > .modalContent > .categoryBox > .name,
body > #root > #modalBox.category > .modalBody > .modalContent > .header > .desc,
body > #root > #modalBox.category > .modalBody > .modalContent > .categoryBox > .desc {
  padding: 10px 15px;
  font-weight: 600;
  color: #FFF;
  background-color: #666;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.category > .modalBody > .modalContent > .header > .name:not(:first-child),
body > #root > #modalBox.category > .modalBody > .modalContent > .categoryBox > .name:not(:first-child),
body > #root > #modalBox.category > .modalBody > .modalContent > .header > .desc:not(:first-child),
body > #root > #modalBox.category > .modalBody > .modalContent > .categoryBox > .desc:not(:first-child) {
  margin-left: 3px;
}
body > #root > #modalBox.category > .modalBody > .modalContent > .header > .name,
body > #root > #modalBox.category > .modalBody > .modalContent > .categoryBox > .name {
  width: 35%;
}
body > #root > #modalBox.category > .modalBody > .modalContent > .header > .desc,
body > #root > #modalBox.category > .modalBody > .modalContent > .categoryBox > .desc {
  width: 65%;
}
body > #root > #modalBox.category > .modalBody > .modalContent > .categoryBox > .name,
body > #root > #modalBox.category > .modalBody > .modalContent > .categoryBox > .desc {
  font-weight: 400;
  color: #000;
  background-color: #F0F0F0;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .header,
body > #root > #modalBox.measure > .modalBody > .modalContent > .measureBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 12px;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .header:not(:first-child),
body > #root > #modalBox.measure > .modalBody > .modalContent > .measureBox:not(:first-child) {
  margin-top: 3px;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .header > .name,
body > #root > #modalBox.measure > .modalBody > .modalContent > .measureBox > .name,
body > #root > #modalBox.measure > .modalBody > .modalContent > .header > .measure,
body > #root > #modalBox.measure > .modalBody > .modalContent > .measureBox > .measure {
  padding: 10px 15px;
  font-weight: 600;
  color: #FFF;
  background-color: #666;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .header > .name:not(:first-child),
body > #root > #modalBox.measure > .modalBody > .modalContent > .measureBox > .name:not(:first-child),
body > #root > #modalBox.measure > .modalBody > .modalContent > .header > .measure:not(:first-child),
body > #root > #modalBox.measure > .modalBody > .modalContent > .measureBox > .measure:not(:first-child) {
  margin-left: 3px;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .header > .name,
body > #root > #modalBox.measure > .modalBody > .modalContent > .measureBox > .name {
  width: 80%;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .header > .measure,
body > #root > #modalBox.measure > .modalBody > .modalContent > .measureBox > .measure {
  width: 20%;
}
body > #root > #modalBox.measure > .modalBody > .modalContent > .measureBox > .name,
body > #root > #modalBox.measure > .modalBody > .modalContent > .measureBox > .measure {
  font-weight: 400;
  color: #000;
  background-color: #F0F0F0;
}
body > #root > #modalBox > .modalBody > .modalContent > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 15px;
}
body > #root > #modalBox > .modalBody > .modalContent > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  background-color: #999;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox > .modalBody > .modalContent > .btns > button:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox > .modalBody > .modalContent > .btns > button.addCat,
body > #root > #modalBox > .modalBody > .modalContent > .btns > button.addMea {
  background-color: #22A822;
}
body > #root > #modalBox > .modalBody > .modalContent > .btns > button.addCat:hover,
body > #root > #modalBox > .modalBody > .modalContent > .btns > button.addMea:hover {
  background-color: #197e19;
}
body > #root > #modalBox > .modalBody > .modalContent > .btns > button.closeCat,
body > #root > #modalBox > .modalBody > .modalContent > .btns > button.closeMea {
  background-color: #999;
}
body > #root > #modalBox > .modalBody > .modalContent > .btns > button.closeCat:hover,
body > #root > #modalBox > .modalBody > .modalContent > .btns > button.closeMea:hover {
  background-color: #737373;
}
