/* Font Family */
/* Borda arredondada */
body > #root > section.secEsc > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.secEsc > main > .contentBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-bottom: 20px;
  width: 100%;
}
body > #root > section.secEsc > main > .contentBox > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  width: 100%;
}
body > #root > section.secEsc > main > .contentBox > .header:not(:first-child) {
  margin-top: 20px;
}
body > #root > section.secEsc > main > .contentBox > .header > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  width: calc(20% - 12px);
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section.secEsc > main > .contentBox > .header > .box:not(:first-child):not(:nth-child(5n+6)) {
  margin-left: 15px;
}
body > #root > section.secEsc > main > .contentBox > .header > .box:nth-child(n+6) {
  margin-top: 15px;
}
body > #root > section.secEsc > main > .contentBox > .header > .box > h5 {
  margin: 0;
  font-size: 36px;
}
body > #root > section.secEsc > main > .contentBox > .header > .box > small {
  font-size: 11px;
  color: rgba(51, 51, 51, 0.7);
  text-align: center;
}
body > #root > section.secEsc > main > .contentBox > .header > .box.docsBox {
  background-color: #4383AD;
}
body > #root > section.secEsc > main > .contentBox > .header > .box.docsBox:hover {
  background-color: #356788;
}
body > #root > section.secEsc > main > .contentBox > .header > .box.reportsBox {
  background-color: #36A952;
}
body > #root > section.secEsc > main > .contentBox > .header > .box.reportsBox:hover {
  background-color: #2a823f;
}
body > #root > section.secEsc > main > .contentBox > .header > .box.docsBox,
body > #root > section.secEsc > main > .contentBox > .header > .box.reportsBox {
  color: #FFF;
}
body > #root > section.secEsc > main > .contentBox > .header > .box.docsBox > small,
body > #root > section.secEsc > main > .contentBox > .header > .box.reportsBox > small {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}
body > #root > section.secEsc > main > .contentBox > .header > .box.docsBox:hover,
body > #root > section.secEsc > main > .contentBox > .header > .box.reportsBox:hover {
  color: #FFF;
}
